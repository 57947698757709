import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import CautionBox from '@Components/application/CautionBox/CautionBox';
import { TooltipPosition } from '@Components/application/Tooltip';
import Button, { ButtonSizes, ButtonTypes } from '@Components/Button';
import MultiSelect from '@Components/form/inputs/MultiSelect/MultiSelect';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import { IconSizes } from '@Components/Icon';
import Icon from '@Components/Icon/Icon';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row/Row';
import LinkButton from '@Components/LinkButton/LinkButton';
import { ClassNamesForOverwrite } from '@Components/Select/SelectOnly';
import Typography from '@Components/Typography';
import { emptySelectorValue } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  CollaboratorRelationshipFe,
  CollaboratorPermission,
  CollaboratorProRelationFe,
  UserAccountType,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { addCollaborator } from '@Store/collaborator/collaborator.actions';
import { getPermissionSelectOption } from '@Utils/form';
import { manipulateTranslations, removeLeadingZeroAndSpaceInContact } from '@Utils/helpers';
import { notificationError } from '@Utils/notificationUtils';
import { validateEmail, validatePhone, validateRequired, validateSelector } from '@Utils/validator';

import { RelationshipLevel, RelationShipDefinition } from '../../../model/Relationship';
import { ModalProviderProps } from '../Modal';

import styles from './InviteToCollaborateModalBody.scss';

export interface InviteToCollaborateModalBodyProps {
  onClose?: () => void;
  buttonText: string;
  secondButtonText?: string;
  secondButtonAction: () => void;
  id: string;
  isLifeCase?: boolean;
}

enum InviteToCollaborateFormFields {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  relationship = 'relationship',
  permission = 'permission',
  phone = 'phone',
}

const InviteToCollaborateModalBody: React.FunctionComponent<ModalProviderProps<InviteToCollaborateModalBodyProps>> = ({
  modalData: { secondButtonText, id, isLifeCase },
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [showEmailCaution, setShowEmailCaution] = React.useState(false);

  const isProfAgent = user?.accountType === UserAccountType.Professional || user?.accountType === UserAccountType.Agent;

  const noOwner = Object.values(CollaboratorPermission)
    .filter((item: CollaboratorPermission) => item !== CollaboratorPermission.Owner)
    .map(getPermissionSelectOption(t));

  const permissionSelectOptions =
    isProfAgent && !isLifeCase ? Object.values(CollaboratorPermission).map(getPermissionSelectOption(t)) : noOwner;

  const relationships: RelationShipDefinition[] = [
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Executor.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Executor,
      relationshipType: RelationshipLevel.firstLevel,
      selected: false,
      index: 0,
      infoTitle: manipulateTranslations(t, 'tooltip_title_executor', 'What is an executor?'),
      infoMessage: isLifeCase ? t('tooltip_desc_lc_executor' as Messages) : t('tooltip_desc_executor' as Messages),
    },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.WillAdministrator.toLowerCase()}` as Messages
      ),
      value: CollaboratorRelationshipFe.WillAdministrator,
      relationshipType: RelationshipLevel.firstLevel,
      selected: false,
      index: 1,
      infoTitle: manipulateTranslations(t, 'tooltip_title_administrator', 'What is an administrator?'),
      infoMessage: isLifeCase
        ? t('tooltip_desc_lc_administrator' as Messages)
        : t('tooltip_desc_administrator' as Messages),
    },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorProRelationFe.LegalRepresentative.toLowerCase()}` as Messages
      ),
      value: CollaboratorProRelationFe.LegalRepresentative,
      relationshipType: RelationshipLevel.firstLevel,
      selected: false,
      index: 2,
      infoTitle: manipulateTranslations(t, 'tooltip_title_legal_representative', 'What is a legal representative?'),
      infoMessage: isLifeCase
        ? t('tooltip_desc_lc_legal_representative' as Messages)
        : t('tooltip_desc_legal_representative' as Messages),
    },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorProRelationFe.OtherAdvisor.toLowerCase()}` as Messages
      ),
      value: CollaboratorProRelationFe.OtherAdvisor,
      relationshipType: RelationshipLevel.firstLevel,
      selected: false,
      index: 3,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Spouse.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Spouse,
      relationshipType: RelationshipLevel.secondLevel,
      selected: false,
      index: 4,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Partner.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Partner,
      relationshipType: RelationshipLevel.secondLevel,
      selected: false,
      index: 5,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Child.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Child,
      relationshipType: RelationshipLevel.secondLevel,
      selected: false,
      index: 6,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Parent.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Parent,
      relationshipType: RelationshipLevel.secondLevel,
      selected: false,
      index: 7,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Sibling.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Sibling,
      relationshipType: RelationshipLevel.secondLevel,
      selected: false,
      index: 8,
    },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.FamilyMember.toLowerCase()}` as Messages
      ),
      value: CollaboratorRelationshipFe.FamilyMember,
      relationshipType: RelationshipLevel.secondLevel,
      selected: false,
      index: 9,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Friend.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Friend,
      relationshipType: RelationshipLevel.secondLevel,
      selected: false,
      index: 10,
    },
  ];

  const relationshipInitialValue: RelationShipDefinition[] = [
    {
      label: '',
      value: '',
      relationshipType: 4,
      index: 200,
    },
  ];

  const initialValues = {
    [InviteToCollaborateFormFields.firstName]: '',
    [InviteToCollaborateFormFields.lastName]: '',
    [InviteToCollaborateFormFields.email]: '',
    [InviteToCollaborateFormFields.permission]: emptySelectorValue,
    [InviteToCollaborateFormFields.relationship]: relationshipInitialValue,
    [InviteToCollaborateFormFields.phone]: '+44',
  };

  const isCollaboratorDefined = (values: any, relationshipName: any) => {
    return values.relationship.find((x: RelationShipDefinition) => x.value === relationshipName)?.selected;
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: 'white',
      width: '581px',
      padding: '14px 24px',
    },
    overlay: {
      border: 'unset',
      borderRadius: '6px',
      background: 'rgba(0, 0, 0, 0.2)',
      zIndex: 1001,
    },
  };

  const handlePermissionChange = (perm: any) => {
    if (perm.value === CollaboratorPermission.Owner && isProfAgent) {
      setIsOpen(true);
    }
  };

  const onFocus = () => setShowEmailCaution(true);

  const onBlur = () => {
    setShowEmailCaution(false);
  };

  return (
    <div className={styles.container}>
      <Typography msg={t(Messages.modalInviteCollaboratorTitle)} tag="h4" size="xl" bold />
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          [InviteToCollaborateFormFields.firstName]: validateRequired(t),
          [InviteToCollaborateFormFields.lastName]: validateRequired(t),
          [InviteToCollaborateFormFields.email]: validateEmail(t),
          [InviteToCollaborateFormFields.permission]: validateSelector(t),
          [InviteToCollaborateFormFields.phone]: validatePhone(t),
        })}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          const relationshipSelected = values.relationship.filter((x: RelationShipDefinition) => x.selected === true);
          if (relationshipSelected.length > 0) {
            dispatch(
              addCollaborator.started({
                baseCase: id,
                email: values.email.trim(),
                firstName: values.firstName,
                lastName: values.lastName,
                relationship:
                  values.relationship
                    .filter((rel: RelationShipDefinition) => rel.relationshipType === RelationshipLevel.secondLevel)
                    .find((x: RelationShipDefinition) => x.selected === true)?.value || undefined,
                permission: values.permission.value as CollaboratorPermission,
                shouldNotify: true,
                phone: removeLeadingZeroAndSpaceInContact(values.phone),
                isAlsoAdministrator: isCollaboratorDefined(values, CollaboratorRelationshipFe.WillAdministrator),
                isAlsoLegal: isCollaboratorDefined(values, CollaboratorProRelationFe.LegalRepresentative),
                isAlsoOtherAdvisor: isCollaboratorDefined(values, CollaboratorProRelationFe.OtherAdvisor),
                isAlsoExecutor: isCollaboratorDefined(values, CollaboratorRelationshipFe.Executor),
                setSubmitting,
                setStatus,
              })
            );
          } else {
            setSubmitting(false);
            dispatch(notificationError(t(Messages.msgRelationshipRequired)));
          }
        }}
      >
        {({ status, isSubmitting, handleSubmit, setFieldValue }) => {
          return (
            <>
              <Form className={styles.formContainer} onSubmit={handleSubmit}>
                <GridRow>
                  <GridCol size={8}>
                    <Field
                      name={InviteToCollaborateFormFields.firstName}
                      label={t(Messages.fieldFirstName)}
                      component={TextInput}
                      required
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol size={8}>
                    <Field
                      name={InviteToCollaborateFormFields.lastName}
                      label={t(Messages.fieldLastName)}
                      component={TextInput}
                      required
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol size={8}>
                    <Field
                      name={InviteToCollaborateFormFields.email}
                      label={t(Messages.fieldEmail)}
                      component={TextInput}
                      required
                      onBlur={onBlur}
                      onFocus={onFocus}
                      onChange={(e: any) => {
                        setFieldValue(InviteToCollaborateFormFields.email, e.target.value);
                      }}
                    />
                  </GridCol>
                </GridRow>
                {showEmailCaution && (
                  <GridRow>
                    <GridCol size={8}>
                      <CautionBox
                        containerClassName={styles.cautionBox}
                        info={manipulateTranslations(
                          t,
                          'label_email_caution',
                          "We'll be sending an email to this individual, inviting them to make an account so they can see the information you've given."
                        )}
                      />
                    </GridCol>
                  </GridRow>
                )}

                <GridRow>
                  <GridCol size={8}>
                    <Field
                      name={InviteToCollaborateFormFields.phone}
                      type="text"
                      component={TextInput}
                      label="Phone number"
                      required
                    />
                  </GridCol>
                </GridRow>

                <GridRow>
                  <GridCol size={8}>
                    <Field
                      name={InviteToCollaborateFormFields.permission}
                      label={t(Messages.labelCollaboratorPermission)}
                      component={SelectInput}
                      options={permissionSelectOptions}
                      selectClass={ClassNamesForOverwrite.SelectMenuPosition}
                      onSelectChange={handlePermissionChange}
                      required
                    />
                  </GridCol>
                </GridRow>

                <GridRow>
                  <GridCol size={11}>
                    <Field
                      name={InviteToCollaborateFormFields.relationship}
                      options={relationships}
                      type="text"
                      component={MultiSelect}
                      label={
                        isLifeCase
                          ? t(Messages.fieldContactRelationship)
                          : t('label_dc_collaborator_relationship' as Messages)
                      }
                      controlDescription="You can choose more than one"
                      position={TooltipPosition.right}
                    />
                  </GridCol>
                </GridRow>

                <FormError formError={status} />
                <div className={classNames(styles.actions)}>
                  <GridRow fullWidth withRowGap>
                    <GridCol size={12}>
                      <Button
                        label={secondButtonText}
                        size={ButtonSizes.fill}
                        type={ButtonTypes.submit}
                        disabled={isSubmitting}
                        icon="person-add"
                        iconSize={IconSizes.ss}
                        loading={isSubmitting}
                        isFullWidth
                        constant
                      />
                    </GridCol>
                  </GridRow>
                </div>
              </Form>
              <Modal
                isOpen={modalIsOpen}
                style={customStyles}
                shouldCloseOnOverlayClick={false}
                shouldFocusAfterRender
                shouldCloseOnEsc={false}
                onRequestClose={() => setIsOpen(false)}
              >
                <Row justifyCenter>
                  <Icon icon="alert-triangle" size={IconSizes.m} />
                </Row>

                <Typography
                  textCenter
                  msg={t('title_transfer_owner' as Messages)}
                  tag="h4"
                  size="xl"
                  bold
                  className={styles.bottomSpacing}
                />

                <Typography
                  textCenter
                  msg={t('text_transfer_owner' as Messages)}
                  tag="div"
                  size="l"
                  className={styles.bottomSpacing}
                />
                <Button onClick={() => setIsOpen(false)} label={t('button_transfer_owner' as Messages)} isFullWidth />

                <LinkButton
                  textCenter
                  className={styles.mt18}
                  linkTitle={t('link_not_transfer' as Messages)}
                  onClick={() => {
                    setFieldValue(InviteToCollaborateFormFields.permission, emptySelectorValue);
                    setIsOpen(false);
                  }}
                />
              </Modal>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default InviteToCollaborateModalBody;
