import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

import DatePickerInput from '@Components/form/inputs/DatePickerInput';
import MultiSelect from '@Components/form/inputs/MultiSelect/MultiSelect';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import NavigationBlock, { NavigationProps } from '@Components/NavigationBlock';
import Typography from '@Components/Typography';
import { LocalStorage, Links, ReactResponsiveQueries, EnterKeyCode } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  DeathCaseNode,
  CollaboratorRelationshipFe,
  RefDeathNode,
  CollaboratorProRelationFe,
  UserTitle,
  UserAccountType,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { createDC, updateDC } from '@Store/dc/dc.actions';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { formatDateForExchange, normalizeDate } from '@Utils/dates';
import { titleOptions } from '@Utils/form';
import { manipulateTranslations } from '@Utils/helpers';
import { getFromLocalStorage, setToLocalStorage, deleteFromLocalStorage } from '@Utils/localStorage';
import { notificationError } from '@Utils/notificationUtils';
import { LoadableItem } from '@Utils/types';
import {
  validateAny,
  validateDate,
  validateMinDate,
  validateName,
  validateRequired,
  validateSelector,
} from '@Utils/validator';

import { RelationshipLevel, RelationShipDefinition } from '../../../../model/Relationship';

import styles from './GeneralForm.scss';

export interface GeneralFormProps extends NavigationProps {
  containerClasses?: string;
  refDeathCaseData?: RefDeathNode;
}

export interface GeneralFormStateProps {
  data: LoadableItem<DeathCaseNode>;
}

interface GeneralFormPrivateProps extends GeneralFormProps, GeneralFormStateProps {}

export enum GeneralFormFields {
  title = 'title',
  middleName = 'middleName',
  firstName = 'firstName',
  lastName = 'lastName',
  birthDate = 'birthDate',
  deathDate = 'deathDate',
  // funeralDate = 'funeralDate',
  relationship = 'relationship',
  titleOther = 'titleOther',
  knownName = 'knownName',
}

const GeneralForm: React.FunctionComponent<GeneralFormPrivateProps> = ({
  back,
  next,
  setSubpage,
  containerClasses,
  data,
}) => {
  const t = useTranslations();
  const history = useHistory();
  const user = useSelector(getUser);
  const queryString = history.location.search;
  const currentLanguage = useSelector(getCurrentLanguage);
  const dispatch = useDispatch();
  const isRes1323 = useMediaQuery({ query: ReactResponsiveQueries.Res1323 });
  const isIpadMini = useMediaQuery({ query: ReactResponsiveQueries.IpadMini });
  const [showNickName, setShowNickName] = React.useState(data.item?.knownName && data.item?.knownName !== '');

  React.useEffect(() => {
    deleteFromLocalStorage(LocalStorage.deathCaseTag);
  });

  if (data.isFetching) {
    return <LoadingSpinner />;
  }

  const isEdit = !!data.item?.id;

  let isEditable = isEdit;
  const isSameDeathCase = getFromLocalStorage(LocalStorage.deathCaseId) || '';
  if (!isEditable) {
    isEditable = isSameDeathCase !== '' ? true : isEditable;
  }
  const setNextStep = () => (next ? setSubpage(next) : {});

  const refDeathCaseData: RefDeathNode = history.location.state as RefDeathNode;

  if (refDeathCaseData) {
    setToLocalStorage(`${LocalStorage.referenceDeathCase}_${user?.firstName}`, refDeathCaseData.id);
  }

  const relationships: RelationShipDefinition[] = [
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Executor.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Executor,
      relationshipType: RelationshipLevel.firstLevel,
      selected: refDeathCaseData?.isAlsoExecutor || data.item?.isAlsoExecutor,
      index: 0,
      infoTitle: manipulateTranslations(t, 'tooltip_title_executor', 'What is an executor?'),
      infoMessage: manipulateTranslations(
        t,
        'tooltip_desc_executor',
        "Anyone who makes a will must name an executor. An executor is legally responsible for carrying out the instructions in the person's will and handling their estate (their money, property, and possessions)."
      ),
    },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.WillAdministrator.toLowerCase()}` as Messages
      ),
      value: CollaboratorRelationshipFe.WillAdministrator,
      relationshipType: RelationshipLevel.firstLevel,
      selected: data.item?.isAlsoAdministrator,
      index: 1,
      infoTitle: manipulateTranslations(t, 'tooltip_title_administrator', 'What is an administrator?'),
      infoMessage: manipulateTranslations(
        t,
        'tooltip_desc_administrator',
        'An administrator is appointed by the courts in the absence of a will. An administrator is legally responsible for handling the estate of the deceased (their money, property, and possessions).'
      ),
    },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorProRelationFe.LegalRepresentative.toLowerCase()}` as Messages
      ),
      value: CollaboratorProRelationFe.LegalRepresentative,
      relationshipType: RelationshipLevel.firstLevel,
      selected: data.item?.isAlsoLegal,
      index: 2,
      infoTitle: manipulateTranslations(t, 'tooltip_title_legal_representative', 'What is a legal representative?'),
      infoMessage: manipulateTranslations(
        t,
        'tooltip_desc_legal_representative',
        'A legal representative would include, for example, a lawyer, solicitor, probate specialist, or any other legal advisor that is not otherwise listed.'
      ),
    },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorProRelationFe.OtherAdvisor.toLowerCase()}` as Messages
      ),
      value: CollaboratorProRelationFe.OtherAdvisor,
      relationshipType: RelationshipLevel.firstLevel,
      selected: data.item?.isAlsoOtherAdvisor,
      index: 3,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Spouse.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Spouse,
      relationshipType: RelationshipLevel.secondLevel,
      selected:
        refDeathCaseData?.relationship === CollaboratorRelationshipFe.Spouse ||
        data.item?.relationship === CollaboratorRelationshipFe.Spouse,
      index: 4,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Partner.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Partner,
      relationshipType: RelationshipLevel.secondLevel,
      selected:
        refDeathCaseData?.relationship === CollaboratorRelationshipFe.Partner ||
        data.item?.relationship === CollaboratorRelationshipFe.Partner,
      index: 5,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Child.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Child,
      relationshipType: RelationshipLevel.secondLevel,
      selected:
        refDeathCaseData?.relationship === CollaboratorRelationshipFe.Child ||
        data.item?.relationship === CollaboratorRelationshipFe.Child,
      index: 6,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Parent.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Parent,
      relationshipType: RelationshipLevel.secondLevel,
      selected:
        refDeathCaseData?.relationship === CollaboratorRelationshipFe.Parent ||
        data.item?.relationship === CollaboratorRelationshipFe.Parent,
      index: 7,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Sibling.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Sibling,
      relationshipType: RelationshipLevel.secondLevel,
      selected:
        refDeathCaseData?.relationship === CollaboratorRelationshipFe.Sibling ||
        data.item?.relationship === CollaboratorRelationshipFe.Sibling,
      index: 8,
    },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.FamilyMember.toLowerCase()}` as Messages
      ),
      value: CollaboratorRelationshipFe.FamilyMember,
      relationshipType: RelationshipLevel.secondLevel,
      selected:
        refDeathCaseData?.relationship === CollaboratorRelationshipFe.FamilyMember ||
        data.item?.relationship === CollaboratorRelationshipFe.FamilyMember,
      index: 9,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Friend.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Friend,
      relationshipType: RelationshipLevel.secondLevel,
      selected:
        refDeathCaseData?.relationship === CollaboratorRelationshipFe.Friend ||
        data.item?.relationship === CollaboratorRelationshipFe.Friend,
      index: 10,
    },
  ];

  const relationshipInitialValue: RelationShipDefinition[] = [
    {
      label: '',
      value: '',
      relationshipType: 4,
      index: 200,
    },
  ];

  const titleInitialValue = () => {
    if (refDeathCaseData && refDeathCaseData.deceasedTitle) {
      const selectedTitle = titleOptions.find(
        (item: any) => item.value.toUpperCase() === refDeathCaseData.deceasedTitle.toUpperCase()
      );
      return selectedTitle;
    }
    return data.item?.title ? titleOptions.find((item) => item.value === data.item?.title) : null;
  };

  const isCollaboratorDefined = (values: any, relationshipName: any) => {
    return values.relationship.find((x: RelationShipDefinition) => x.value === relationshipName)?.selected;
  };

  const handleScrollToError = (errors: Record<string, any>) => {
    let firstErrorFieldName = Object.keys(errors)[0]; // Get the first field with an error
    firstErrorFieldName = firstErrorFieldName === 'title' ? 'firstName' : firstErrorFieldName;
    // Try to fetch the element by name
    let fieldElement = document.querySelector(`[name="${firstErrorFieldName}"]`) as HTMLElement | null;

    // If not found, try to fetch by ID
    if (!fieldElement) {
      fieldElement = document.getElementById(firstErrorFieldName) as HTMLElement | null;
    }

    if (fieldElement) {
      fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); // Smooth scroll to the element
      fieldElement.focus(); // Focus on the field for better accessibility
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          [GeneralFormFields.firstName]: refDeathCaseData?.deceasedFirstName || data.item?.firstName || '',
          [GeneralFormFields.lastName]: refDeathCaseData?.deceasedLastName || data.item?.lastName || '',
          [GeneralFormFields.birthDate]: refDeathCaseData?.birthDate || data.item?.birthDate || '',
          [GeneralFormFields.deathDate]: refDeathCaseData?.deathDate || data.item?.deathDate || '',
          // [GeneralFormFields.funeralDate]: refDeathCaseData?.funeralDate || data.item?.funeralDate || '',

          [GeneralFormFields.middleName]: data.item?.middleName || '',
          [GeneralFormFields.title]: titleInitialValue(),

          [GeneralFormFields.relationship]: relationshipInitialValue,
          [GeneralFormFields.titleOther]: data.item?.titleOther || '',
          [GeneralFormFields.knownName]: data.item?.knownName || '',
        }}
        validationSchema={() => {
          return Yup.lazy((values: any) => {
            return Yup.object({
              // [GeneralFormFields.title]: validateRequired(t),
              [GeneralFormFields.firstName]: validateName(t),
              [GeneralFormFields.lastName]: validateName(t),
              [GeneralFormFields.birthDate]: validateDate(t, true),
              [GeneralFormFields.deathDate]: validateMinDate(
                t,
                GeneralFormFields.birthDate,
                t(Messages.msgErrorFieldDeathDate),
                true
              ),
              // [GeneralFormFields.funeralDate]: validateFuneralDate(
              //   GeneralFormFields.deathDate,
              //   t(Messages.msgErrorFieldFuneralDate)
              // ),
              [GeneralFormFields.title]: validateSelector(t),
              [GeneralFormFields.titleOther]:
                values.title?.value === UserTitle.Other ? validateRequired(t) : validateAny(),
            });
          });
        }}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          const relationshipSelected = values.relationship.filter((x: RelationShipDefinition) => x.selected === true);
          //const {titleOther } = values;
          if (relationshipSelected.length > 0) {
            const submitValues = {
              ...values,
              birthDate: formatDateForExchange(values.birthDate),
              deathDate: formatDateForExchange(values.deathDate),
              // funeralDate: formatDateForExchange(values.funeralDate),
              relationship:
                values.relationship
                  .filter((rel: RelationShipDefinition) => rel.relationshipType === RelationshipLevel.secondLevel)
                  .find((x: RelationShipDefinition) => x.selected === true)?.value || '',
              title: values.title?.value,
              isAlsoAdministrator: isCollaboratorDefined(values, CollaboratorRelationshipFe.WillAdministrator),
              isAlsoLegal: isCollaboratorDefined(values, CollaboratorProRelationFe.LegalRepresentative),
              isAlsoOtherAdvisor: isCollaboratorDefined(values, CollaboratorProRelationFe.OtherAdvisor),
              isAlsoExecutor: isCollaboratorDefined(values, CollaboratorRelationshipFe.Executor),
              titleOther: values.titleOther === '' ? undefined : values.titleOther,
            };

            if (isEditable) {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const itemId = data.item === null ? isSameDeathCase : data!.item!.id!;

              dispatch(updateDC.started({ setSubmitting, setStatus, setNextStep, id: itemId, ...submitValues }));
            } else if (refDeathCaseData) {
              dispatch(
                createDC.started({
                  setSubmitting,
                  setStatus,
                  ...submitValues,
                  firstName: submitValues.firstName!.trim(), // eslint-disable-line @typescript-eslint/no-non-null-assertion
                  lastName: submitValues.lastName!.trim(), // eslint-disable-line @typescript-eslint/no-non-null-assertion
                  refDeathCaseId: refDeathCaseData?.id,
                  queryString,
                  hasAuthority: user?.accountType === UserAccountType.Professional ? true : undefined,
                })
              );
            } else {
              dispatch(
                createDC.started({
                  setSubmitting,
                  setStatus,
                  setNextStep,
                  ...submitValues,
                  firstName: submitValues.firstName!.trim(), // eslint-disable-line @typescript-eslint/no-non-null-assertion
                  lastName: submitValues.lastName!.trim(), // eslint-disable-line @typescript-eslint/no-non-null-assertion
                  queryString,
                  hasAuthority: user?.accountType === UserAccountType.Professional ? true : undefined,
                })
              );
            }
            setToLocalStorage(LocalStorage.noDcContent, 'true');
          } else {
            setSubmitting(false);
            dispatch(notificationError(t(Messages.msgRelationshipRequired)));
          }
        }}
      >
        {({ isSubmitting, status, values, errors, handleSubmit, touched }) => (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();

              // Allow time for errors to be updated
              setTimeout(() => {
                if (Object.keys(errors).length > 0) {
                  handleScrollToError(errors);
                }
              }, 0); // Ensure it's triggered after Formik updates `errors`
            }}
          >
            <div className={containerClasses}>
              <GridRow>
                <GridCol constant size={2}>
                  <Field
                    name={GeneralFormFields.title}
                    component={SelectInput}
                    label="Title"
                    placeholder={Messages.placeholderTitle}
                    options={titleOptions}
                    required
                    error={touched[GeneralFormFields.title] && errors[GeneralFormFields.title]}
                  />
                </GridCol>
              </GridRow>
              {values.title?.value === UserTitle.Other && (
                <Row className={isIpadMini ? '' : styles.knownNameSpacing}>
                  <Field
                    name={GeneralFormFields.titleOther}
                    type="text"
                    component={TextInput}
                    label={t('field_title_other' as Messages)}
                    fullWidth={true}
                  />
                </Row>
              )}
              <GridRow>
                <GridCol size={5}>
                  <Field
                    name={GeneralFormFields.firstName}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldFirstName)}
                    placeholder={t(Messages.placeholderFirstName)}
                    required
                    error={touched[GeneralFormFields.firstName] && errors[GeneralFormFields.firstName]}
                  />
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol size={5}>
                  <Field
                    placeholder={t('placeholder_middle_name' as Messages)}
                    name={GeneralFormFields.middleName}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldMiddleName)}
                  />
                </GridCol>
              </GridRow>
              <Row className={styles.lastNameRow}>
                <Col className={styles.lastNameField}>
                  <Field
                    name={GeneralFormFields.lastName}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldLastName)}
                    placeholder={t(Messages.placeholderSurName)}
                    required
                    error={touched[GeneralFormFields.lastName] && errors[GeneralFormFields.lastName]}
                  />
                </Col>
                <Col alignBottom={!isRes1323}>
                  <Row
                    constant
                    onClick={() => setShowNickName(!showNickName)}
                    onKeyDown={(e: any) => {
                      if (e.keyCode === EnterKeyCode) {
                        setShowNickName(!showNickName);
                      }
                    }}
                    className={styles.nickNameLink}
                    alignCenter
                    tabIndex={0}
                  >
                    <Typography underline msg="Were they known by any other name?" tag="div" size="l" />
                    <Icon size={IconSizes.s} icon={showNickName ? 'chevronUp' : 'chevronDown'} />
                  </Row>
                </Col>
              </Row>
              {showNickName && (
                <Row className={isIpadMini ? '' : styles.knownNameSpacing}>
                  <Field
                    name={GeneralFormFields.knownName}
                    type="text"
                    component={TextInput}
                    label={t('field_known_name' as Messages)}
                    fullWidth={true}
                  />
                </Row>
              )}
              <GridRow className={styles.topSpacing}>
                <GridCol size={9}>
                  <Field
                    name={GeneralFormFields.birthDate}
                    component={DatePickerInput}
                    parse={normalizeDate(currentLanguage)}
                    label="Date of "
                    boldLabel="birth*"
                    nextFieldName={GeneralFormFields.deathDate}
                    error={touched[GeneralFormFields.birthDate] && errors[GeneralFormFields.birthDate]}
                  />
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol size={9}>
                  <Field
                    name={GeneralFormFields.deathDate}
                    component={DatePickerInput}
                    parse={normalizeDate(currentLanguage)}
                    label="Date of "
                    boldLabel="death*"
                    error={touched[GeneralFormFields.deathDate] && errors[GeneralFormFields.deathDate]}
                  />
                </GridCol>
              </GridRow>

              <GridRow className={styles.topSpacing}>
                <GridCol size={12}>
                  <Field
                    name={GeneralFormFields.relationship}
                    options={relationships}
                    type="text"
                    component={MultiSelect}
                    label={t(Messages.fieldRelationship)}
                    controlDescription="You can choose more than one"
                  />
                </GridCol>
              </GridRow>
              <FormError formError={status} />
            </div>
            <NavigationBlock
              isSubmitting={isSubmitting}
              back={back}
              next={next}
              setSubpage={setSubpage}
              hideSkip={true}
              backUrl={Links.home}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default GeneralForm;
