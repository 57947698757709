import classNames from 'classnames';
import * as React from 'react';
import ReactSelect from 'react-select';

import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { noop } from '@Utils/helpers';

import styles from './Select.scss';
import { ClassNamesForOverwrite } from './SelectOnly';

export type OptionItem = {
  label: string;
  value: any;
  labelDisplay?: JSX.Element;
  extraFlag?: string;
};

export type SelectSizes = 's' | 'm' | 'l' | 'xl';

export interface SelectProps {
  placeholder?: Messages;
  options: OptionItem[];
  input?: any;
  isMulti?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  size?: SelectSizes;
  onChange?: (value: OptionItem) => void;
  withError?: boolean;
  disabled?: boolean;
  className?: string;
  selectClass?: string;
  customStyles?: any;
  onFocus?: () => void;
  name?: string;
}

const Select: React.FunctionComponent<SelectProps> = (props) => {
  const {
    options = [],
    placeholder,
    input,
    isClearable,
    isMulti,
    isSearchable = true,
    isLoading = false,
    size = 'm',
    withError,
    disabled,
    onChange,
    className,
    selectClass,
    customStyles,
    onFocus = noop,
  } = props;

  const DropdownIndicator: React.FunctionComponent<{}> = () => {
    return <Icon size={IconSizes.s} icon="chevronDown" className={styles.dropDownPosition} />;
  };

  const LoadingMessage = () => {
    return (
      <Row className={styles.loadingContainer} alignCenter justifyCenter>
        <LoadingSpinner isGraySpinner circumference="44px" />
      </Row>
    );
  };

  const t = useTranslations();

  return (
    <>
      <ReactSelect
        {...input}
        isSearchable={isSearchable}
        placeholder={t(placeholder || Messages.defaultSelectPlaceholder)}
        onChange={onChange}
        formatOptionLabel={(opt: { value: string; label: string; labelDisplay?: JSX.Element }) => {
          return opt.labelDisplay ? opt.labelDisplay : opt.label;
        }}
        className={classNames(
          selectClass,
          `size-${size}`,
          withError && ClassNamesForOverwrite.SelectWithErrors,
          className
        )}
        classNamePrefix="Select"
        options={options}
        isMulti={isMulti}
        isLoading={isLoading}
        isClearable={isClearable}
        components={{ DropdownIndicator, LoadingMessage }}
        isDisabled={disabled}
        styles={customStyles}
        onFocus={() => onFocus()}
        id={input.name}
      />
    </>
  );
};

export default Select;
