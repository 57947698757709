import classNames from 'classnames';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import GoogleSignIn from '@Components/application/GoogleSignIn';
import Button, { ButtonTypes } from '@Components/Button';
import AutoComplete from '@Components/form/inputs/AutoComplete';
import Checkbox from '@Components/form/inputs/Checkbox';
import { CheckBoxTypes } from '@Components/form/inputs/Checkbox/Checkbox';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import Icon from '@Components/Icon';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import Title from '@Components/Title';
import Typography from '@Components/Typography';
import { Links, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { UserAccountType, UserLandingPage, UserReferralSource, UserTitle } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import EmailSentPage from '@Routes/auth/EmailSentPage';
import { ForgotPasswordFormValues } from '@Routes/auth/ForgotPasswordPage/ForgotPasswordForm/ForgotPasswordForm';
import { register } from '@Store/auth/auth.actions';
import { titleOptions } from '@Utils/form';
import { chooseAccountType } from '@Utils/helpers';
import { notificationError } from '@Utils/notificationUtils';
import {
  validateConfirmPassword,
  validateRequired,
  validateEmail,
  validateName,
  validateRequiredBoolean,
  validateAny,
  validateSelector,
} from '@Utils/validator';

import styles from './IndividualRegisterForm.scss';

export interface IndividualRegisterFormProps {
  emailSentTo: string;
  setEmailSentTo: Dispatch<SetStateAction<string>>;
}

interface IndividualRegisterFormValues {
  title: { value: string; label: string } | undefined;
  referralSource: { value: string; label: string };
}

enum IndividualRegisterFormFields {
  title = 'title',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  password = 'password',
  confirmPassword = 'confirm_password',
  terms = 'terms',
  referralSource = 'referralSource',
  referralSourceDetails = 'referralSourceDetails',
  optIn = 'optIn',
  titleOther = 'titleOther',
}

export enum ReferralSource {
  BereavementSupportOrganisation = 'BEREAVEMENT_SUPPORT_ORGANISATION',
  Charity = 'CHARITY',
  FuneralDirector = 'FUNERAL_DIRECTOR',
  News = 'NEWS',
  Nhs = 'NHS',
  SearchEngine = 'SEARCH_ENGINE',
  Solicitor = 'SOLICITOR',
  Other = 'OTHER',
  Company = 'COMPANY',
  Hospice = 'HOSPICE',
  LifeHospice = 'LIFE_HOSPICE',
}

export interface PasswordGuide {
  message: string;
  state: boolean;
}

const IndividualRegisterForm: React.FunctionComponent<IndividualRegisterFormProps> = ({
  emailSentTo,
  setEmailSentTo,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const accessCode = searchParams.get('access_code') || '';
  const emailId = searchParams.get('email') || '';
  const inviteFirstName = searchParams.get('first') || '';
  const inviteLastName = searchParams.get('last') || '';
  const inviteTitle = searchParams.get('title') || '';
  const refSourceParam = searchParams.get('ref_src') || '';
  const refDetailsParam = searchParams.get('ref_details') || '';
  const source = searchParams.get('source') || undefined;
  const paramCollaboratorKey = searchParams.get('collaborator_key') || undefined;
  const landingPageKey = searchParams.get('case') || undefined;
  const refSrcKey = searchParams.get('ref') || undefined;
  const { collaboratorKey } = useParams();

  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const [passwordValue, setPasswordValue] = React.useState('');
  const [guidanceMessages, setGuidanceMessages] = React.useState<PasswordGuide[]>([]);
  const [showGuidanceMessages, setShowGuidanceMessages] = React.useState(false);
  const [refSrc, setRefSrc] = React.useState('OTHER');

  const referralSourceOptions = refSourceParam !== '' ? refSourceParam : undefined;

  const titleInitialValue =
    inviteTitle !== '' ? titleOptions.find((item) => item.value === inviteTitle.toUpperCase()) : null;

  const refSourceInitialValue =
    referralSourceOptions?.toUpperCase() === ReferralSource.LifeHospice
      ? ReferralSource.LifeHospice
      : referralSourceOptions;

  const onPasswordChange = (e: any) => {
    const passwordEntry = e.target.value;
    const alphabetRegex = /[a-zA-Z]/g;
    const numberRegex = /\d/;
    const msgs: PasswordGuide[] = [];
    setPasswordValue(passwordEntry);
    if (passwordEntry.length >= 8) {
      msgs.push({ message: 'Atleast 8 characters.', state: true });
    } else {
      msgs.push({ message: 'Atleast 8 characters.', state: false });
    }
    if (alphabetRegex.test(passwordEntry) && numberRegex.test(passwordEntry)) {
      msgs.push({ message: 'A mixture of letters and numbers.', state: true });
    } else {
      msgs.push({ message: 'A mixture of letters and numbers.', state: false });
    }
    if (/[A-Z]/.test(passwordEntry) && /[a-z]/.test(passwordEntry)) {
      msgs.push({ message: 'A mixture of both uppercase and lowercase letters.', state: true });
    } else {
      msgs.push({ message: 'A mixture of both uppercase and lowercase letters.', state: false });
    }
    setShowGuidanceMessages(true);
    setGuidanceMessages(msgs);
  };

  const onFocus = () => setShowGuidanceMessages(true);
  const onBlur = () => {
    if (guidanceMessages.filter((gm: any) => gm.state).length === 3) {
      setShowGuidanceMessages(false);
    } else {
      setShowGuidanceMessages(true);
    }
  };
  // const getReferralSourceLabel = (referralSource: string): string => {
  //   if (referralSource === ReferralSource.Other) {
  //     return t(Messages.labelReferralSourceOther);
  //   }
  //   if (referralSource === ReferralSource.Company) {
  //     return t(Messages.labelReferralSourceCompany);
  //   }
  //   return t(Messages.labelReferralSourceDetails);
  // };

  const setReferralType = (refType: string) => {
    setRefSrc(refType);
  };

  const RegisterInputForm = ({ isSubmitting, status, values }: FormikProps<IndividualRegisterFormValues>) => (
    <Form>
      {isMobile && (
        <>
          <GridRow>
            <GridCol size={12}>
              <Title text={t(Messages.registerFormTitle)} />
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol size={12}>
              <Typography msg={t(Messages.labelRegisterFree)} size="l" tag="div" />
            </GridCol>
          </GridRow>
        </>
      )}
      <GridRow className={styles.spacing}>
        <GridCol size={6}>
          <Field
            name={IndividualRegisterFormFields.title}
            component={SelectInput}
            label={t(Messages.fieldTitle)}
            options={titleOptions}
            required
          />
        </GridCol>
      </GridRow>
      {values.title?.value === UserTitle.Other && (
        <GridRow className={styles.spacing}>
          <GridCol size={6}>
            <Field
              name={IndividualRegisterFormFields.titleOther}
              type="text"
              component={TextInput}
              label={t('field_title_other' as Messages)}
              required
            />
          </GridCol>
        </GridRow>
      )}
      <GridRow className={styles.spacing}>
        <GridCol size={12}>
          <Field
            name={IndividualRegisterFormFields.firstName}
            type="text"
            component={TextInput}
            label={t(Messages.fieldFirstName)}
            helperText={t('label_name_govt_id' as Messages)}
            required
          />
        </GridCol>
      </GridRow>
      <GridRow className={styles.spacing}>
        <GridCol size={12}>
          <Field
            name={IndividualRegisterFormFields.lastName}
            type="text"
            component={TextInput}
            label={t(Messages.fieldSurname)}
            required
          />
        </GridCol>
      </GridRow>
      <GridRow className={styles.spacing}>
        <GridCol size={12}>
          <Field
            name={IndividualRegisterFormFields.email}
            type="text"
            component={TextInput}
            label={t(Messages.fieldEmail)}
            required
          />
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol size={6}>
          <Field
            name={IndividualRegisterFormFields.password}
            type="password"
            component={TextInput}
            value={passwordValue}
            label={t(Messages.fieldPassword)}
            onChange={onPasswordChange}
            onBlur={onBlur}
            onFocus={onFocus}
            required
          />
        </GridCol>

        <GridCol size={6}>
          <Field
            name={IndividualRegisterFormFields.confirmPassword}
            type="password"
            component={TextInput}
            label={t(Messages.fieldConfirmPassword)}
            onFocus={onFocus}
            onBlur={onBlur}
            required
          />
        </GridCol>
      </GridRow>
      <GridRow className={styles.guidance}>
        <GridCol size={8}>
          {showGuidanceMessages &&
            guidanceMessages.map((m: PasswordGuide, idx: number) => {
              return (
                <div
                  key={idx}
                  className={classNames(m.state ? styles.passwordCorrect : styles.error, styles.withErrorIcon)}
                >
                  <Icon icon={m.state ? 'tick' : 'warningSign'} className={styles.iconPlacing} />
                  <Typography className={styles.message} tag="span" msg={m.message} />
                </div>
              );
            })}
        </GridCol>
      </GridRow>
      {!refSrcKey && !collaboratorKey && (
        <GridRow className={styles.spacing}>
          <GridCol size={12}>
            <Field
              name={IndividualRegisterFormFields.referralSource}
              component={AutoComplete}
              organisationControl
              onSelectClick={setReferralType}
              placeholder={t('placeholder_referral_source' as Messages)}
              label={t(Messages.labelReferralSource)}
              disabled={refSourceParam.toUpperCase() === UserReferralSource.LifeHospice}
              required
            />
          </GridCol>
        </GridRow>
      )}

      {/* {showOther && (
        <GridRow className={styles.spacing}>
          <GridCol size={12}>
            <Field
              name={IndividualRegisterFormFields.referralSourceDetails}
              type="text"
              component={AutoComplete}
              label={getReferralSourceLabel(values.referralSource.value)}
              disabled={refDetailsParam !== ''}
              organisationControl
            />
          </GridCol>
        </GridRow>
      )} */}

      <GridRow className={styles.spacing}>
        <GridCol className={styles.buttonCenter} size={12}>
          <Link to={{ pathname: Links.lifeLedger + Links.termsConditions }} target="_blank" className={styles.links}>
            <Typography color="electricBlue" size="mb" tag="span" bold msg={t(Messages.labelTermsConditionPage)} />
          </Link>
        </GridCol>
      </GridRow>
      <GridRow className={styles.spacing}>
        <GridCol size={12}>
          <Field
            name={IndividualRegisterFormFields.terms}
            label={t(Messages.fieldTerms)}
            component={Checkbox}
            type={CheckBoxTypes.checkbox}
          />
        </GridCol>
      </GridRow>
      <GridRow className={styles.spacing}>
        <GridCol size={12}>
          <Field
            name={IndividualRegisterFormFields.optIn}
            label={t(Messages.fieldOptIn)}
            component={Checkbox}
            type={CheckBoxTypes.checkbox}
          />
        </GridCol>
      </GridRow>
      <FormError formError={status} />
      <Row alignCenter justifyCenter>
        <Button
          type={ButtonTypes.submit}
          disabled={isSubmitting}
          loading={isSubmitting}
          label={t(Messages.buttonCreateAccount)}
        />
      </Row>
      <GoogleSignIn registrationPage collaboratorKey={collaboratorKey} />
    </Form>
  );

  const EmailSentComponent = ({ setStatus }: FormikProps<ForgotPasswordFormValues>) => (
    <EmailSentPage isRegister email={emailSentTo} setStatus={setStatus} />
  );

  return (
    <Formik
      initialValues={{
        [IndividualRegisterFormFields.title]: titleInitialValue,
        [IndividualRegisterFormFields.firstName]: inviteFirstName.trim(),
        [IndividualRegisterFormFields.lastName]: inviteLastName.trim(),
        [IndividualRegisterFormFields.email]: emailId.trim(),
        [IndividualRegisterFormFields.password]: '',
        [IndividualRegisterFormFields.confirmPassword]: '',
        [IndividualRegisterFormFields.terms]: false,
        [IndividualRegisterFormFields.referralSource]: refSourceInitialValue,
        [IndividualRegisterFormFields.referralSourceDetails]: refDetailsParam.trim(),
        [IndividualRegisterFormFields.optIn]: false,
        [IndividualRegisterFormFields.titleOther]: '',
      }}
      validationSchema={() => {
        return Yup.lazy((values: any) => {
          return Yup.object({
            [IndividualRegisterFormFields.title]: validateSelector(t),
            [IndividualRegisterFormFields.firstName]: validateName(t),
            [IndividualRegisterFormFields.lastName]: validateName(t),
            [IndividualRegisterFormFields.email]: validateEmail(t),
            [IndividualRegisterFormFields.referralSource]:
              !refSrcKey && !collaboratorKey ? validateRequired(t) : validateAny(),
            [IndividualRegisterFormFields.password]: passwordValue !== '' ? validateAny() : validateRequired(t),
            [IndividualRegisterFormFields.confirmPassword]: validateConfirmPassword(
              t,
              passwordValue,
              values.confirmPassword
            ),
            [IndividualRegisterFormFields.terms]: validateRequiredBoolean(t),
            // [IndividualRegisterFormFields.referralSourceDetails]:
            //   values.referralSource && values.referralSource.value === ReferralSource.Other
            //     ? validateRequired(t)
            //     : validateAny(),
            [IndividualRegisterFormFields.titleOther]:
              values.title && values.title.value === UserTitle.Other ? validateRequired(t) : validateAny(),
          });
        });
      }}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        const guidanceLength = guidanceMessages.filter((gm: PasswordGuide) => !gm.state);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { firstName, lastName, email, referralSource, title, optIn, titleOther } = values;
        if (location.pathname.split('/')[2].toUpperCase()) {
          const acctType = chooseAccountType(location.pathname.split('/')[2].toUpperCase());
          dispatch(
            register.started({
              setSubmitting,
              setStatus,
              setEmailSentTo,
              firstName,
              lastName,
              email: email.trim(),
              isGuidanceRight: guidanceLength.length === 0,
              password: passwordValue,
              referralSource: refSrc,
              referralSourceDetails: referralSource,
              collaboratorKey: paramCollaboratorKey || collaboratorKey,
              accessCode,
              title: title?.value,
              accountType: acctType,
              optOut: optIn,
              referralCode: source,
              ref: refSrcKey,
              titleOther: titleOther === '' ? undefined : titleOther,
              landingPage:
                acctType === UserAccountType.Individual && landingPageKey?.toLowerCase() === 'life'
                  ? UserLandingPage.LifeCase
                  : undefined,
            })
          );
        } else {
          dispatch(notificationError(t('msg_account_type_not_chosen' as Messages)));
          history.push(Links.register);
        }
      }}
    >
      {emailSentTo ? EmailSentComponent : RegisterInputForm}
    </Formik>
  );
};

export default IndividualRegisterForm;
