import { useMutation } from '@apollo/react-hooks';
import classNames from 'classnames';
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

import DatePickerInput from '@Components/form/inputs/DatePickerInput';
import MultiSelect from '@Components/form/inputs/MultiSelect/MultiSelect';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import ToggleSwitchInput from '@Components/form/inputs/ToggleSwitchInput';
import FormError from '@Components/FormError';
import Icon, { IconSizes } from '@Components/Icon/Icon';
import Col from '@Components/layout/Col/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import NavigationBlock, { NavigationProps } from '@Components/NavigationBlock';
import Typography from '@Components/Typography';
import { LocalStorage, Links, ReactResponsiveQueries, PaymentStatus } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  CollaboratorProRelationFe,
  CollaboratorRelationshipFe,
  FpHolderNode,
  LifeCaseNode,
  StripeUpdateCheckoutSessionMutation,
  StripeUpdateCheckoutSessionMutationVariables,
  UserAccountType,
  UserTitle,
} from '@Graphql/graphqlTypes.generated';
import { mutationStripeUpdateCheckoutSession } from '@Graphql/payments/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { createLC, updateLC } from '@Store/lc/lc.actions';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { formatDateForExchange, normalizeDate } from '@Utils/dates';
import { normaliseGqlError, titleOptions } from '@Utils/form';
import { manipulateTranslations } from '@Utils/helpers';
import { deleteFromLocalStorage, getFromLocalStorage, setToLocalStorage } from '@Utils/localStorage';
import { notificationError } from '@Utils/notificationUtils';
import { LoadableItem } from '@Utils/types';
import { validateAny, validateDate, validateEmail, validateName } from '@Utils/validator';

import { RelationshipLevel, RelationShipDefinition } from '../../../../model/Relationship';

import styles from './GeneralForm.scss';

export interface GeneralFormProps extends NavigationProps {
  containerClasses?: string;
  refLifeCaseData?: FpHolderNode;
}

export interface GeneralFormStateProps {
  data: LoadableItem<LifeCaseNode>;
}

interface GeneralFormPrivateProps extends GeneralFormProps, GeneralFormStateProps {}

export enum GeneralFormFields {
  title = 'title',
  isSelf = 'isSelf',
  firstName = 'firstName',
  middleName = 'middleName',
  lastName = 'lastName',
  birthDate = 'birthDate',
  email = 'email',
  phone = 'phone',
  address1 = 'address1',
  address2 = 'address2',
  city = 'city',
  postalCode = 'postalCode',
  propertyType = 'propertyType',
  occupancyStatus = 'occupancyStatus',
  isTerminallyIll = 'isTerminallyIll',
  relationship = 'relationship',
  titleOther = 'titleOther',
}

export interface GeneralFormValues {
  [GeneralFormFields.firstName]: string;
  [GeneralFormFields.lastName]: string;
  [GeneralFormFields.birthDate]: string;
  [GeneralFormFields.title]: string;
  [GeneralFormFields.isSelf]: boolean;
  [GeneralFormFields.email]: string;
}

const emptyFormValues = {
  [GeneralFormFields.firstName]: '',
  [GeneralFormFields.lastName]: '',
  [GeneralFormFields.birthDate]: '',
  [GeneralFormFields.email]: '',
  [GeneralFormFields.title]: null,
  [GeneralFormFields.titleOther]: '',
};

type GeneralFormValueFields = Extract<
  GeneralFormFields,
  | GeneralFormFields.firstName
  | GeneralFormFields.lastName
  | GeneralFormFields.birthDate
  | GeneralFormFields.email
  | GeneralFormFields.title
  | GeneralFormFields.titleOther
>;

const GeneralForm: React.FunctionComponent<GeneralFormPrivateProps> = ({
  back,
  next,
  setSubpage,
  containerClasses,
  data,
}) => {
  const t = useTranslations();
  const currentLanguage = useSelector(getCurrentLanguage);
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const queryString = history.location.search;
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const searchParams = new URLSearchParams(queryString);
  const sessionId = searchParams.get('session_id') || undefined;
  const isTerminallyIllParam = searchParams.get('ill') || undefined;

  const [updateCheckoutSession] = useMutation<
    StripeUpdateCheckoutSessionMutation,
    StripeUpdateCheckoutSessionMutationVariables
  >(mutationStripeUpdateCheckoutSession, {
    onError: (mutCheckError: any) => {
      dispatch(notificationError(normaliseGqlError(mutCheckError.message)));
    },
  });

  React.useEffect(() => {
    deleteFromLocalStorage(LocalStorage.lifeCaseTag);
    if (sessionId) {
      updateCheckoutSession({ variables: { input: { sessionId, status: PaymentStatus.SUCCESS } } });
    }
  }, [sessionId, updateCheckoutSession]);

  if (data.isFetching) {
    return <LoadingSpinner />;
  }

  const titleInitialValue = () => {
    if (refLifeCaseData) {
      return titleOptions.find((item) => item.value.toUpperCase() === refLifeCaseData.title.toUpperCase());
    }
    return data.item?.title ? titleOptions.find((item) => item.value === data.item?.title) : null;
  };

  const userDataValues = {
    [GeneralFormFields.firstName]: user?.firstName,
    [GeneralFormFields.lastName]: user?.lastName,
    [GeneralFormFields.birthDate]: user?.birthDate,
    [GeneralFormFields.email]: user?.email,
    [GeneralFormFields.title]: user?.title ? titleOptions.find((item) => item.value === user?.title) : null,
    [GeneralFormFields.titleOther]: user?.titleOther,
  };

  const isEdit = !!data.item?.id;

  let isEditable = isEdit;
  const isSameLifeCase = getFromLocalStorage(LocalStorage.lifeCaseId) || '';
  if (!isEditable) {
    isEditable = isSameLifeCase !== '' ? true : isEditable;
  }
  const setNextStep = () => (next ? setSubpage(next) : {});

  const refLifeCaseData: FpHolderNode = history.location.state as FpHolderNode;

  if (refLifeCaseData) {
    setToLocalStorage(`${LocalStorage.referenceLifeCase}_${user?.firstName}`, refLifeCaseData.id);
  }

  const AutoToggleForm = () => {
    const { setFieldValue, setFieldTouched, resetForm, values } = useFormikContext();
    const { isSelf } = values as GeneralFormValues;

    React.useEffect(() => {
      const getFieldValue = (fieldName: GeneralFormValueFields) => {
        return isSelf ? userDataValues[fieldName] : emptyFormValues[fieldName];
      };
      const toggleExecutorFormField = (fieldName: GeneralFormValueFields) => {
        setFieldValue(fieldName, getFieldValue(fieldName), false);
        setFieldTouched(fieldName, false, false);
      };
      if (!isEdit) {
        toggleExecutorFormField(GeneralFormFields.firstName);
        toggleExecutorFormField(GeneralFormFields.lastName);
        toggleExecutorFormField(GeneralFormFields.email);
        toggleExecutorFormField(GeneralFormFields.title);
        toggleExecutorFormField(GeneralFormFields.birthDate);
        toggleExecutorFormField(GeneralFormFields.titleOther);
      }
      //toggleExecutorFormField(GeneralFormFields.title);
    }, [setFieldValue, setFieldTouched, resetForm, isSelf]);

    return null;
  };

  const relationships: RelationShipDefinition[] = [
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Executor.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Executor,
      relationshipType: RelationshipLevel.firstLevel,
      selected: data.item?.isAlsoExecutor,
      index: 0,
      infoTitle: manipulateTranslations(t, 'tooltip_title_executor', 'What is an executor?'),
      infoMessage: t('tooltip_desc_lc_executor' as Messages),
    },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.WillAdministrator.toLowerCase()}` as Messages
      ),
      value: CollaboratorRelationshipFe.WillAdministrator,
      relationshipType: RelationshipLevel.firstLevel,
      selected: data.item?.isAlsoAdministrator,
      index: 1,
      infoTitle: manipulateTranslations(t, 'tooltip_title_administrator', 'What is an administrator?'),
      infoMessage: t('tooltip_desc_lc_administrator' as Messages),
    },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorProRelationFe.LegalRepresentative.toLowerCase()}` as Messages
      ),
      value: CollaboratorProRelationFe.LegalRepresentative,
      relationshipType: RelationshipLevel.firstLevel,
      selected: data.item?.isAlsoLegal,
      index: 2,
      infoTitle: manipulateTranslations(t, 'tooltip_title_legal_representative', 'What is a legal representative?'),
      infoMessage: t('tooltip_desc_lc_legal_representative' as Messages),
    },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorProRelationFe.OtherAdvisor.toLowerCase()}` as Messages
      ),
      value: CollaboratorProRelationFe.OtherAdvisor,
      relationshipType: RelationshipLevel.firstLevel,
      selected: data.item?.isAlsoOtherAdvisor,
      index: 3,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Spouse.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Spouse,
      relationshipType: RelationshipLevel.secondLevel,
      selected: data.item?.relationship === CollaboratorRelationshipFe.Spouse,
      index: 4,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Partner.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Partner,
      relationshipType: RelationshipLevel.secondLevel,
      selected: data.item?.relationship === CollaboratorRelationshipFe.Partner,
      index: 5,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Child.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Child,
      relationshipType: RelationshipLevel.secondLevel,
      selected: data.item?.relationship === CollaboratorRelationshipFe.Child,
      index: 6,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Parent.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Parent,
      relationshipType: RelationshipLevel.secondLevel,
      selected: data.item?.relationship === CollaboratorRelationshipFe.Parent,
      index: 7,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Sibling.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Sibling,
      relationshipType: RelationshipLevel.secondLevel,
      selected: data.item?.relationship === CollaboratorRelationshipFe.Sibling,
      index: 8,
    },
    {
      label: t(
        `${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.FamilyMember.toLowerCase()}` as Messages
      ),
      value: CollaboratorRelationshipFe.FamilyMember,
      relationshipType: RelationshipLevel.secondLevel,
      selected: data.item?.relationship === CollaboratorRelationshipFe.FamilyMember,
      index: 9,
    },
    {
      label: t(`${Messages.labelRelationshipPrefix}${CollaboratorRelationshipFe.Friend.toLowerCase()}` as Messages),
      value: CollaboratorRelationshipFe.Friend,
      relationshipType: RelationshipLevel.secondLevel,
      selected: data.item?.relationship === CollaboratorRelationshipFe.Friend,
      index: 10,
    },
  ];

  const relationshipInitialValue: RelationShipDefinition[] = [
    {
      label: '',
      value: '',
      relationshipType: 4,
      index: 200,
    },
  ];

  const isCollaboratorDefined = (values: any, relationshipName: any) => {
    return values.relationship.find((x: RelationShipDefinition) => x.value === relationshipName)?.selected;
  };

  return (
    <>
      {/* {refLifeCaseData && (
        <Row constant className={styles.tipContainer}>
          <Col className={styles.leftSide}>
            <Icon className={styles.iconSpacing} icon="info" />
          </Col>

          <Col className={styles.rightSide}>
            <Row column className={styles.tipBoxSpacing}>
              <Typography
                size="s"
                tag="span"
                className={styles.tipContentSpacing}
                msg={t('label_create_referrred_case_info' as Messages)}
              />
              <div className={styles.handSymbol}>
                <Typography
                  underline
                  color="electricBlue"
                  size="l"
                  bold
                  tag="span"
                  msg={t('link_do_it_later' as Messages)}
                />
              </div>
            </Row>
          </Col>
        </Row>
      )} */}
      <Formik
        initialValues={{
          [GeneralFormFields.isSelf]: data.item?.isSelf || false,
          [GeneralFormFields.title]: titleInitialValue(),
          [GeneralFormFields.firstName]: refLifeCaseData?.firstName || data.item?.firstName || '',
          [GeneralFormFields.middleName]: data.item?.middleName || '',
          [GeneralFormFields.lastName]: refLifeCaseData?.lastName || data.item?.lastName || '',
          [GeneralFormFields.birthDate]: data.item?.birthDate || '',
          [GeneralFormFields.email]: refLifeCaseData?.email || data.item?.email || '',

          [GeneralFormFields.isTerminallyIll]: isTerminallyIllParam === 'true' || data.item?.isTerminallyIll || false,
          [GeneralFormFields.postalCode]: data.item?.postalCode || '',
          [GeneralFormFields.relationship]: relationshipInitialValue,
          [GeneralFormFields.titleOther]: data.item?.titleOther || '',
        }}
        validationSchema={() => {
          return Yup.lazy((values: any) => {
            return Yup.object({
              [GeneralFormFields.firstName]: values.isSelf ? validateAny() : validateName(t),
              [GeneralFormFields.lastName]: values.isSelf ? validateAny() : validateName(t),
              [GeneralFormFields.email]: values.isSelf ? validateAny() : validateEmail(t),
              [GeneralFormFields.birthDate]: values.isSelf ? validateAny() : validateDate(t, true),
              // [GeneralFormFields.titleOther]:
              //   values.title?.value === UserTitle.Other ? validateRequired(t) : validateAny(),
            });
          });
        }}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          const relationshipSelected = values.relationship.filter((x: RelationShipDefinition) => x.selected === true);
          const { titleOther } = values;
          if (relationshipSelected.length === 0 && !values.isSelf) {
            setSubmitting(false);
            dispatch(notificationError(t('msg_relationship_lc_required' as Messages)));
          } else {
            const submitValues = {
              ...values,
              email: values.email.trim(),
              birthDate: formatDateForExchange(values.birthDate),
              title: values.title?.value,
              relationship:
                values.relationship
                  .filter((rel: RelationShipDefinition) => rel.relationshipType === RelationshipLevel.secondLevel)
                  .find((x: RelationShipDefinition) => x.selected === true)?.value || '',
              isAlsoAdministrator: isCollaboratorDefined(values, CollaboratorRelationshipFe.WillAdministrator),
              isAlsoLegal: isCollaboratorDefined(values, CollaboratorProRelationFe.LegalRepresentative),
              isAlsoOtherAdvisor: isCollaboratorDefined(values, CollaboratorProRelationFe.OtherAdvisor),
              isAlsoExecutor: isCollaboratorDefined(values, CollaboratorRelationshipFe.Executor),
              titleOther: titleOther === '' ? undefined : titleOther,
            };
            if (isEditable) {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const itemId = data.item === null ? isSameLifeCase : data!.item!.id!;
              dispatch(updateLC.started({ setSubmitting, setStatus, setNextStep, id: itemId, ...submitValues }));
            } else if (refLifeCaseData) {
              dispatch(
                createLC.started({
                  setSubmitting,
                  setStatus,
                  ...submitValues,
                  firstName: submitValues.firstName!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                  lastName: submitValues.lastName!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                  //  refDeathCaseId: refLifeCaseData?.id,
                  queryString,
                  hasAuthority: user?.accountType === UserAccountType.Professional ? true : undefined,
                })
              );
            } else {
              dispatch(
                createLC.started({
                  setSubmitting,
                  setStatus,
                  setNextStep,

                  ...submitValues,
                  firstName: submitValues.firstName!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                  lastName: submitValues.lastName!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                  // phone: submitValues.phone!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                  email: submitValues.email!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                  queryString,
                  hasAuthority: user?.accountType === UserAccountType.Professional ? true : undefined,
                })
              );
            }
            setToLocalStorage(LocalStorage.noLcContent, 'true');
          }
        }}
      >
        {({ values, isSubmitting, status, dirty, isValid }) => (
          <Form>
            <div className={containerClasses}>
              <GridRow>
                <GridCol size={5}>
                  <Field
                    name={GeneralFormFields.isSelf}
                    label="Are you registering your own life?"
                    component={ToggleSwitchInput}
                  />
                </GridCol>
              </GridRow>

              <GridRow>
                <GridCol size={12} className={styles.spacing}>
                  <Typography
                    msg={
                      values.isSelf
                        ? 'Please check that the details that you have provided are correct'
                        : 'If no, please provide details of the person whose life you are registering'
                    }
                    tag="span"
                  />
                </GridCol>
              </GridRow>
              <GridRow className={styles.spacing}>
                <GridCol size={2}>
                  <Field
                    name={GeneralFormFields.title}
                    component={SelectInput}
                    label={t(Messages.fieldTitle)}
                    options={titleOptions}
                  />
                </GridCol>
              </GridRow>
              {values.title?.value === UserTitle.Other && (
                <Row className={styles.titleOtherSpacing}>
                  <Field
                    name={GeneralFormFields.titleOther}
                    type="text"
                    component={TextInput}
                    label={t('field_title_other' as Messages)}
                    fullWidth
                  />
                </Row>
              )}
              <GridRow>
                <GridCol size={5}>
                  <Field
                    name={GeneralFormFields.firstName}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldFirstName)}
                    required
                  />
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol size={5}>
                  <Field
                    name={GeneralFormFields.middleName}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldMiddleName)}
                    required={false}
                  />
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol size={5}>
                  <Field
                    name={GeneralFormFields.lastName}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldLastName)}
                    required
                  />
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol size={5}>
                  <Field
                    name={GeneralFormFields.email}
                    type="text"
                    component={TextInput}
                    label={t(Messages.fieldEmail)}
                    required
                  />
                </GridCol>
                {/* <GridCol size={6}>
                    <Field
                      name={GeneralFormFields.phone}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldPhone)}
                      required
                    />
                  </GridCol> */}
              </GridRow>
              <GridRow>
                <GridCol size={5}>
                  <Field
                    name={GeneralFormFields.birthDate}
                    component={DatePickerInput}
                    parse={normalizeDate(currentLanguage)}
                    label={t(Messages.fieldBirthDate)}
                    useFieldValue={data.item === null && values.isSelf}
                  />
                </GridCol>
              </GridRow>

              <GridRow>
                <GridCol size={5}>
                  <Field
                    name={GeneralFormFields.isTerminallyIll}
                    label={t('label_is_terminally_ill' as Messages)}
                    component={ToggleSwitchInput}
                    disabled={isTerminallyIllParam !== undefined}
                  />
                </GridCol>
              </GridRow>
              {!values.isSelf && (
                <GridRow>
                  <GridCol size={12}>
                    <Field
                      name={GeneralFormFields.relationship}
                      options={relationships}
                      type="text"
                      component={MultiSelect}
                      label={t('field_life_relationship' as Messages)}
                      controlDescription="You can choose more than one"
                    />
                  </GridCol>
                </GridRow>
              )}
              <AutoToggleForm />
              <FormError formError={status} />
            </div>
            <NavigationBlock
              isSubmitting={isSubmitting}
              back={back}
              next={next}
              setSubpage={setSubpage}
              hideSkip={true}
              backUrl={Links.home}
              isDisabled={isEdit ? !isValid : !dirty}
            />
            {!values.isSelf && (
              <Row className={styles.mt10} size={12}>
                {!isTablet && <Col size={5} />}
                <Col size={!isTablet ? 7 : 10}>
                  <Row constant className={classNames({ [styles.pl8]: !isTablet })}>
                    <Icon className={styles.bulbIcon} icon="bulb" size={IconSizes.s} />
                    <Typography msg={t(Messages.labelWarnBeforeLifeCaseSave)} size="m" tag="div" color="footerColor" />
                  </Row>
                </Col>
              </Row>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default GeneralForm;
