import classNames from 'classnames';
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory, useLocation } from 'react-router';
import * as Yup from 'yup';

import Popover, { PopoverPosition } from '@Components/application/Popover';
import Button, { ButtonStyles, ButtonTypes } from '@Components/Button';
import RadioButton from '@Components/form/inputs/MultiRadioButton';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import ToggleSwitchInput from '@Components/form/inputs/ToggleSwitchInput';
import FormError from '@Components/FormError';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import NavigationBlock, { NavigationProps } from '@Components/NavigationBlock';
import { ClassNamesForOverwrite } from '@Components/Select/SelectOnly';
import Typography from '@Components/Typography';
import {
  validationLimits,
  LocalStorage,
  AddressPortal,
  ReactResponsiveQueries,
  Links,
  CaseTags,
  EnterKeyCode,
} from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  BaseCaseOccupancyStatus,
  BaseCasePropertyType,
  DeathCaseNode,
  MissingInfoNode,
  useRefDeathByIdQuery,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { updateDC } from '@Store/dc/dc.actions';
import { addressHelper, disableNextButton } from '@Utils/helpers';
import {
  getFromLocalStorage,
  setToLocalStorage,
  deleteFromLocalStorage,
  getFromLocalStorageString,
} from '@Utils/localStorage';
import { notificationError } from '@Utils/notificationUtils';
import { LoadableItem } from '@Utils/types';
import {
  validateAny,
  validateMaxLen,
  validateRequired,
  validatePostalCodeCharacters,
  validateMinLength,
  validateSelector,
} from '@Utils/validator';

import { Address, FullAddress } from '../../../../model/Address';
import { DeathCaseSubpages } from '../deathCaseSubpages';

import styles from './GeneralFormAddress.scss';

export interface GeneralFormProps extends NavigationProps {
  containerClasses?: string;
}

export interface GeneralFormStateProps {
  data: LoadableItem<DeathCaseNode>;
}

interface GeneralFormPrivateProps extends GeneralFormProps, GeneralFormStateProps {}

export enum GeneralFormFields {
  buildingName = 'buildingName',
  buildingNumber = 'buildingNumber',
  postalCode = 'postalCode',
  city = 'city',
  autoAddress = 'autoAddress',
  chooseAddress = 'chooseAddress',
  address1 = 'address1',
  address2 = 'address2',

  propertyType = 'propertyType',
  occupancyStatus = 'occupancyStatus',

  previousAddress1 = 'previousAddress1',
  previousAddress2 = 'previousAddress2',
  previousPostalCode = 'previousPostalCode',
  previousCity = 'previousCity',
  prevBuildingName = 'prevBuildingName',
  prevBuildingNumber = 'prevBuildingNumber',

  isAddressOverThree = 'isAddressOverThree',
  previousAutoAddress = 'previousAutoAddress',
  choosePrevAddress = 'choosePrevAddress',
}

export interface GeneralFormAddressValues {
  [GeneralFormFields.autoAddress]: string;
  [GeneralFormFields.isAddressOverThree]: boolean;
}

const GeneralFormAddress: React.FunctionComponent<GeneralFormPrivateProps> = ({
  back,
  next,
  setSubpage,
  containerClasses,
  data,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const [postCodeTabOut, setPostCodeTabOut] = React.useState(false);
  const [prevPostCodeTabOut, setPrevPostCodeTabOut] = React.useState(false);

  const refDeathCaseDataId = getFromLocalStorageString(`${LocalStorage.referenceDeathCase}_${user?.firstName}`);

  const referCaseQueryData = useRefDeathByIdQuery({
    variables: {
      id: refDeathCaseDataId,
    },
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    skip: refDeathCaseDataId === '',
  });

  const refDeathCaseData = referCaseQueryData.data?.refDeathById;

  const matchCreateTag = location.pathname.split('/').includes('tag=radc');

  const missingItems = data.item?.missingInfo.filter(
    (m: MissingInfoNode) =>
      !m.value && (m.field.includes('address') || m.field.includes('city') || m.field.includes('post'))
  );

  const [manualAddress, setManualAddress] = React.useState(!!data.item?.address1 || refDeathCaseDataId !== '');

  const [previousManualAddress, setPreviousManualAddress] = React.useState(!!data.item?.previousAddress1);

  const [addressResult, setAddressResult] = React.useState<FullAddress[]>([]);
  const [prevAddressResult, setPrevAddressResult] = React.useState<FullAddress[]>([]);

  const [currentPostalCode, setCurrentPostalCode] = React.useState('');
  const [prevPostalCode, setPrevPostalCode] = React.useState('');

  const [searching, setSearching] = React.useState(false);
  const [prevSearching, setPrevSearching] = React.useState(false);

  const [assignedAddress, setAssignedAddress] = React.useState(-1);
  const [prevAssignedAddress, setPrevAssignedAddress] = React.useState(-2);

  const [showClearAddress, setShowClearAddress] = React.useState(false);
  const [showPrevClearAddress, setShowPrevClearAddress] = React.useState(false);

  React.useEffect(() => {
    deleteFromLocalStorage(LocalStorage.deathCaseTag);
    window.onpopstate = (e: any) => {
      e.stopPropagation();
      history.push(
        generatePath(`${Links.dcEdit}`, {
          id: data.item?.id,
          subpage: DeathCaseSubpages.General,
          tag: `tag=${CaseTags.deathCaseEdit}`,
        })
      );
    };
  }, [refDeathCaseData, setCurrentPostalCode, setShowClearAddress, data.item, history]);

  if (data.isFetching || referCaseQueryData.loading) {
    return <LoadingSpinner />;
  }

  // const getSaveButton = (isSubmitting: boolean) => (
  //   <Button type={ButtonTypes.submit} size={ButtonSizes.fill} loading={isSubmitting} label="Save" isFullWidth />
  // );

  const isEdit = !!data.item?.id;

  let isEditable = isEdit;
  const isSameDeathCase = getFromLocalStorage(LocalStorage.deathCaseId) || '';

  if (!isEditable) {
    isEditable = isSameDeathCase !== '' ? true : isEditable;
  }
  const setNextStep = () => (next ? setSubpage(next) : {});

  const resultAddressOptions =
    addressResult &&
    addressResult.map((add: FullAddress) => ({
      label: add.formattedAddress,
      value: add.id,
    }));

  const resultPrevAddressOptions =
    prevAddressResult &&
    prevAddressResult.map((add: FullAddress) => ({
      label: add.formattedAddress,
      value: add.id,
    }));

  const getAddress = (previous: boolean) => {
    const paramPostalCode = previous ? prevPostalCode : currentPostalCode;
    if (paramPostalCode !== '') {
      if (previous) {
        setPrevSearching(true);
      } else {
        setSearching(true);
      }

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      fetch(AddressPortal.replace('postalCode', paramPostalCode), requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((apiData) => {
          if (apiData.Message) {
            dispatch(notificationError(apiData.Message));
            if (previous) {
              setPrevAddressResult([]);
              setPrevSearching(false);
            } else {
              setAddressResult([]);
              setSearching(false);
            }
          } else {
            const outputData = new Address(apiData);
            if (outputData.addresses.length === 0) {
              dispatch(notificationError('Address not found for this postal code'));
            }
            if (previous) {
              setPrevAddressResult(outputData.addresses);
              setPrevSearching(false);
            } else {
              setAddressResult(outputData.addresses);
              setSearching(false);
            }
          }
        });
    }
  };

  const AutoToggleForm = () => {
    const { setFieldValue, values } = useFormikContext();
    const { isAddressOverThree } = values as GeneralFormAddressValues;

    React.useEffect(() => {
      const searchedAddress = addressResult.find((addr: FullAddress) => addr.id === assignedAddress);
      if (searchedAddress) {
        setFieldValue(GeneralFormFields.buildingName, addressHelper(searchedAddress.buildingName));
        setFieldValue(GeneralFormFields.buildingNumber, addressHelper(searchedAddress.buildingNumber));
        setFieldValue(GeneralFormFields.address1, addressHelper(searchedAddress.address1));
        setFieldValue(GeneralFormFields.address2, addressHelper(searchedAddress.address2));
        setFieldValue(GeneralFormFields.city, addressHelper(searchedAddress.city));
        setFieldValue(GeneralFormFields.postalCode, addressHelper(searchedAddress.postalCode));
      }

      const prevSearchedAddress = prevAddressResult.find((addr: FullAddress) => addr.id === prevAssignedAddress);
      if (prevSearchedAddress) {
        setFieldValue(GeneralFormFields.prevBuildingName, addressHelper(prevSearchedAddress.buildingName));
        setFieldValue(GeneralFormFields.prevBuildingNumber, addressHelper(prevSearchedAddress.buildingNumber));
        setFieldValue(GeneralFormFields.previousAddress1, addressHelper(prevSearchedAddress.address1));
        setFieldValue(GeneralFormFields.previousAddress2, addressHelper(prevSearchedAddress.address2));
        setFieldValue(GeneralFormFields.previousCity, addressHelper(prevSearchedAddress.city));
        setFieldValue(GeneralFormFields.previousPostalCode, addressHelper(prevSearchedAddress.postalCode));
      }

      if (isAddressOverThree) {
        setFieldValue(GeneralFormFields.prevBuildingName, '');
        setFieldValue(GeneralFormFields.prevBuildingNumber, '');
        setFieldValue(GeneralFormFields.previousAddress1, '');
        setFieldValue(GeneralFormFields.previousAddress2, '');
        setFieldValue(GeneralFormFields.previousCity, '');
        setFieldValue(GeneralFormFields.previousPostalCode, '');
        setPreviousManualAddress(false);
      }
    }, [setFieldValue, isAddressOverThree]);

    return null;
  };

  const handleAddressChange = (item: any) => {
    setAssignedAddress(item.value);
    setManualAddress(true);
  };

  const handlePrevAddressChange = (item: any) => {
    setPrevAssignedAddress(item.value);
    setPreviousManualAddress(true);
  };

  const baseCaseProperties = Object.values(BaseCasePropertyType).map((baseCaseProps) => ({
    label: t(`${Messages.labelBaseCasePropertyPrefix}${baseCaseProps.toLowerCase()}` as Messages),
    value: baseCaseProps,
  }));

  const occupancyStatusOptions = Object.values(BaseCaseOccupancyStatus).map((occupancy) => ({
    label: t(`${Messages.labelOccupancyStatusPrefix}${occupancy.toLowerCase()}` as Messages),
    value: occupancy,
  }));

  const isMissingItem = missingItems && missingItems?.length > 0 && !matchCreateTag;

  const handleScrollToError = (errors: Record<string, any>) => {
    const firstErrorFieldName = Object.keys(errors)[0]; // Get the first field with an error
    // Try to fetch the element by name
    let fieldElement = document.querySelector(`[name="${firstErrorFieldName}"]`) as HTMLElement | null;

    // If not found, try to fetch by ID
    if (!fieldElement) {
      fieldElement = document.getElementById(firstErrorFieldName) as HTMLElement | null;
    }

    if (fieldElement) {
      fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); // Smooth scroll to the element
      fieldElement.focus(); // Focus on the field for better accessibility
    }
  };

  return (
    <Formik
      initialValues={{
        [GeneralFormFields.postalCode]: refDeathCaseData?.postalCode || data.item?.postalCode || '',
        [GeneralFormFields.city]: refDeathCaseData?.city || data.item?.city || '',
        [GeneralFormFields.address1]: refDeathCaseData?.address1 || data.item?.address1 || '',
        [GeneralFormFields.address2]: refDeathCaseData?.address2 || data.item?.address2 || '',

        [GeneralFormFields.buildingName]: refDeathCaseData?.buildingName || data.item?.buildingName || '',
        [GeneralFormFields.buildingNumber]: refDeathCaseData?.buildingNumber || data.item?.buildingNumber || '',

        [GeneralFormFields.isAddressOverThree]: data.item?.isAddressOverThree || true,

        [GeneralFormFields.previousPostalCode]: data.item?.previousPostalCode || '',
        [GeneralFormFields.previousCity]: data.item?.previousCity || '',
        [GeneralFormFields.previousAddress1]: data.item?.previousAddress1 || '',
        [GeneralFormFields.previousAddress2]: data.item?.previousAddress2 || '',

        [GeneralFormFields.prevBuildingName]: data.item?.prevBuildingName || '',
        [GeneralFormFields.prevBuildingNumber]: data.item?.prevBuildingNumber || '',

        [GeneralFormFields.propertyType]: data.item?.propertyType || '',
        [GeneralFormFields.occupancyStatus]: data.item?.occupancyStatus || '',
      }}
      validationSchema={() =>
        Yup.lazy((values: any) => {
          return Yup.object({
            [GeneralFormFields.propertyType]: validateRequired(t),
            [GeneralFormFields.occupancyStatus]:
              values.propertyType === BaseCasePropertyType.InResidentialCare ? validateAny() : validateRequired(t),
            [GeneralFormFields.isAddressOverThree]: validateRequired(t),
            [GeneralFormFields.postalCode]: validateMaxLen(t, validationLimits.postalCode, true),
            [GeneralFormFields.previousPostalCode]: validateMaxLen(t, validationLimits.postalCode, false),
            [GeneralFormFields.chooseAddress]: resultAddressOptions.length > 0 ? validateSelector(t) : validateAny(),
            [GeneralFormFields.choosePrevAddress]:
              resultPrevAddressOptions.length > 0 ? validateSelector(t) : validateAny(),
            [GeneralFormFields.address1]: isMissingItem
              ? validateRequired(t, 'label_compulsory_field' as Messages)
              : validateAny(),
            [GeneralFormFields.city]: isMissingItem ? validateRequired(t) : validateAny(),
            //[GeneralFormFields.postalCode]: isMissingItem ? validateRequired(t) : validateAny(),
          });
        })
      }
      validateOnMount={true}
      initialTouched={{
        [GeneralFormFields.address1]: isMissingItem,
        [GeneralFormFields.city]: isMissingItem,
        [GeneralFormFields.postalCode]: isMissingItem,
      }}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        const submitValues = {
          isAddressOverThree: values.isAddressOverThree,

          buildingName: values.buildingName,
          buildingNumber: values.buildingNumber,
          address1: values.address1,
          address2: values.address2,
          city: values.city,
          postalCode: values.postalCode,

          prevBuildingName: values.prevBuildingName,
          prevBuildingNumber: values.prevBuildingNumber,
          previousAddress1: values.previousAddress1,
          previousAddress2: values.previousAddress2,
          previousCity: values.previousCity,
          previousPostalCode: values.previousPostalCode,

          propertyType: Object.values(BaseCasePropertyType).find((bcp: any) => values.propertyType === bcp),
          occupancyStatus: Object.values(BaseCaseOccupancyStatus).find((bos: any) => values.occupancyStatus === bos),
        };
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const itemId = data.item === null ? isSameDeathCase : data!.item!.id!;
        dispatch(
          updateDC.started({ setSubmitting, setStatus, setNextStep, id: itemId, ...submitValues, navigateAway: true })
        );
        setToLocalStorage(LocalStorage.noDcContent, 'true');
      }}
    >
      {({ isSubmitting, status, values, isValid, handleSubmit, errors }) => (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();

            // Allow time for errors to be updated
            setTimeout(() => {
              if (Object.keys(errors).length > 0) {
                handleScrollToError(errors);
              }
            }, 0); // Ensure it's triggered after Formik updates `errors`
          }}
        >
          <div className={containerClasses}>
            <Typography tag="div" size="l" msg={t(Messages.fieldDeceasedPostcodeInput)} />
            <Row className={styles.addressContainer} size={12}>
              <Col size={isTablet ? 12 : 7}>
                <input
                  name={GeneralFormFields.autoAddress}
                  type="text"
                  value={currentPostalCode}
                  className={styles.input}
                  autoComplete="off"
                  onChange={(e: any) => {
                    setCurrentPostalCode(e.currentTarget.value);
                    setShowClearAddress(true);
                  }}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter' && validatePostalCodeCharacters(currentPostalCode)) {
                      e.preventDefault();
                      getAddress(false);
                    }
                  }}
                  onBlur={() => {
                    if (validateMinLength(currentPostalCode) && resultAddressOptions.length === 0) {
                      setPostCodeTabOut(true);
                    } else {
                      setPostCodeTabOut(false);
                    }
                  }}
                />
                {showClearAddress && (
                  <Icon
                    icon="close-outline"
                    className={styles.visibilityToggleIcon}
                    onClick={() => {
                      setShowClearAddress(false);
                      setCurrentPostalCode('');
                      setAddressResult([]);
                      setAssignedAddress(-1);
                      setPostCodeTabOut(false);
                    }}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        setShowClearAddress(false);
                        setCurrentPostalCode('');
                        setAddressResult([]);
                        setAssignedAddress(-1);
                        setPostCodeTabOut(false);
                      }
                    }}
                    size={IconSizes.s}
                  />
                )}
              </Col>

              <Col size={isTablet ? 12 : 5} className={styles.searchButtonContainer}>
                <Popover
                  showContent={postCodeTabOut}
                  position={isMobile ? PopoverPosition.bottom : PopoverPosition.top}
                  title={t('title_postcode_warning' as Messages)}
                  message={t('deSaveButtonsc_postcode_warning' as Messages)}
                >
                  <Button
                    type={ButtonTypes.button}
                    style={ButtonStyles.transparent}
                    loading={searching}
                    label={t(Messages.buttonSearch)}
                    icon="search"
                    iconSize={IconSizes.sxs}
                    isFullWidth={true}
                    onClick={() => {
                      setPostCodeTabOut(false);
                      getAddress(false);
                    }}
                    constant
                    disabled={!validatePostalCodeCharacters(currentPostalCode)}
                  />
                </Popover>
              </Col>
            </Row>

            {resultAddressOptions.length > 0 && (
              <Row className={styles.topSpacing}>
                <Col size={12}>
                  <Field
                    name={GeneralFormFields.chooseAddress}
                    component={SelectInput}
                    label=""
                    placeholder={t('label_select_your_address' as Messages)}
                    options={resultAddressOptions}
                    disabled={!resultAddressOptions.length}
                    onSelectChange={handleAddressChange}
                    onFocus={() => setPostCodeTabOut(false)}
                    selectClass={ClassNamesForOverwrite.SelectAddress}
                    hasErrorProp={assignedAddress === -1}
                  />
                </Col>
              </Row>
            )}

            <GridRow>
              <GridCol className={styles.adresslink} size={9}>
                <div
                  onKeyDown={(e: any) => {
                    if (e.keyCode === EnterKeyCode) {
                      setManualAddress(!manualAddress);
                    }
                  }}
                  onClick={() => setManualAddress(!manualAddress)}
                >
                  <Typography msg="Or enter address manually" tag="span" tabIndex={0} />
                </div>
              </GridCol>
            </GridRow>

            {(manualAddress || isMissingItem) && (
              <>
                <GridRow>
                  <GridCol size={4}>
                    <Field
                      name={GeneralFormFields.buildingName}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldBuildingName)}
                    />
                  </GridCol>
                  <GridCol size={4}>
                    <Field
                      name={GeneralFormFields.buildingNumber}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldBuildingNumber)}
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol size={12}>
                    <Field
                      name={GeneralFormFields.address1}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldDCAddress1)}
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol size={12}>
                    <Field
                      name={GeneralFormFields.address2}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldDCAddress2)}
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol size={4}>
                    <Field
                      name={GeneralFormFields.city}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldCity)}
                    />
                  </GridCol>
                  <GridCol size={4}>
                    <Field
                      name={GeneralFormFields.postalCode}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldPostalCode)}
                    />
                  </GridCol>
                </GridRow>
              </>
            )}

            <GridRow>
              <GridCol size={5}>
                <Field
                  name={GeneralFormFields.isAddressOverThree}
                  label="Did the deceased live here for more than 3 years?"
                  component={ToggleSwitchInput}
                  required
                />
              </GridCol>
            </GridRow>
            {!values.isAddressOverThree && (
              <>
                <Typography tag="div" size="l" msg={t(Messages.fieldAddPreviousAddress)} />
                <Row className={styles.addressContainer} size={12}>
                  <Col size={isTablet ? 12 : 7}>
                    <input
                      name={GeneralFormFields.previousAutoAddress}
                      type="text"
                      value={prevPostalCode}
                      autoComplete="off"
                      className={styles.input}
                      onChange={(e: any) => {
                        setPrevPostalCode(e.currentTarget.value);
                        setShowPrevClearAddress(true);
                      }}
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter' && validatePostalCodeCharacters(prevPostalCode)) {
                          e.preventDefault();
                          getAddress(true);
                        }
                      }}
                      onBlur={() => {
                        if (validateMinLength(prevPostalCode) && resultPrevAddressOptions.length === 0) {
                          setPrevPostCodeTabOut(true);
                        } else {
                          setPrevPostCodeTabOut(false);
                        }
                      }}
                    />
                    {showPrevClearAddress && (
                      <Icon
                        icon="close-outline"
                        className={styles.visibilityToggleIcon}
                        size={IconSizes.s}
                        onClick={() => {
                          setShowPrevClearAddress(false);
                          setPrevPostalCode('');
                          setPrevAddressResult([]);
                          setPrevAssignedAddress(-2);
                          setPrevPostCodeTabOut(false);
                        }}
                        onKeyDown={(e: any) => {
                          if (e.key === 'Enter') {
                            setShowPrevClearAddress(false);
                            setPrevPostalCode('');
                            setPrevAddressResult([]);
                            setPrevAssignedAddress(-2);
                            setPrevPostCodeTabOut(false);
                          }
                        }}
                      />
                    )}
                  </Col>
                  <Col size={isTablet ? 12 : 5} className={classNames(styles.searchButtonContainer)}>
                    <Popover
                      showContent={prevPostCodeTabOut}
                      position={isMobile ? PopoverPosition.bottom : PopoverPosition.top}
                      title={t('title_postcode_warning' as Messages)}
                      message={t('desc_postcode_warning' as Messages)}
                    >
                      <Button
                        type={ButtonTypes.button}
                        style={ButtonStyles.transparent}
                        loading={prevSearching}
                        label={t(Messages.buttonSearch)}
                        icon="search"
                        iconSize={IconSizes.sxs}
                        onClick={() => {
                          setPrevPostCodeTabOut(false);
                          getAddress(true);
                        }}
                        isFullWidth={true}
                        constant
                        disabled={!validatePostalCodeCharacters(prevPostalCode)}
                      />
                    </Popover>
                  </Col>
                </Row>
                {resultPrevAddressOptions.length > 0 && (
                  <Row className={styles.topSpacing} size={12}>
                    <Col size={12}>
                      <Field
                        name={GeneralFormFields.choosePrevAddress}
                        component={SelectInput}
                        label=""
                        placeholder="---- Select your previous address ----"
                        options={resultPrevAddressOptions}
                        disabled={!resultPrevAddressOptions.length}
                        onSelectChange={handlePrevAddressChange}
                        isFullWidth={isMobile || isTablet}
                        constant
                        onFocus={() => setPrevPostCodeTabOut(false)}
                        hasErrorProp={prevAssignedAddress === -1}
                      />
                    </Col>
                  </Row>
                )}
                <GridRow>
                  <GridCol className={styles.adresslink} size={9}>
                    <div
                      onKeyDown={(e: any) => {
                        if (e.keyCode === EnterKeyCode) {
                          setManualAddress(!manualAddress);
                        }
                      }}
                      onClick={() => setPreviousManualAddress(!previousManualAddress)}
                    >
                      <Typography msg="Or enter address manually" tag="span" tabIndex={0} />
                    </div>
                  </GridCol>
                </GridRow>
              </>
            )}

            {previousManualAddress && (
              <>
                <GridRow>
                  <GridCol size={4}>
                    <Field
                      name={GeneralFormFields.prevBuildingName}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldBuildingName)}
                    />
                  </GridCol>
                  <GridCol size={4}>
                    <Field
                      name={GeneralFormFields.prevBuildingNumber}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldBuildingNumber)}
                    />
                  </GridCol>
                </GridRow>

                <GridRow>
                  <GridCol size={12}>
                    <Field
                      name={GeneralFormFields.previousAddress1}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldDCAddress1)}
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol size={12}>
                    <Field
                      name={GeneralFormFields.previousAddress2}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldDCAddress2)}
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol size={4}>
                    <Field
                      name={GeneralFormFields.previousCity}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldCity)}
                    />
                  </GridCol>
                  <GridCol size={4}>
                    <Field
                      name={GeneralFormFields.previousPostalCode}
                      type="text"
                      component={TextInput}
                      label={t(Messages.fieldPostalCode)}
                    />
                  </GridCol>
                </GridRow>
              </>
            )}
            <GridRow>
              <GridCol size={12}>
                <Field
                  name={GeneralFormFields.propertyType}
                  options={baseCaseProperties}
                  component={RadioButton}
                  label="Please enter the ownership status of their property"
                  required
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol size={5}>
                <Typography
                  msg="This is information often requested by utilities and insurers"
                  tag="div"
                  color="footerColor"
                  size="s"
                />
              </GridCol>
            </GridRow>
            {values.propertyType !== BaseCasePropertyType.InResidentialCare && (
              <>
                <GridRow>
                  <GridCol size={12}>
                    <Field
                      name={GeneralFormFields.occupancyStatus}
                      options={occupancyStatusOptions}
                      component={RadioButton}
                      label="Is the property currently occupied?"
                      required
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol size={5}>
                    <Typography
                      msg="This is information often requested by utilities and insurers"
                      tag="div"
                      color="footerColor"
                      size="s"
                    />
                  </GridCol>
                </GridRow>
              </>
            )}
            <AutoToggleForm />
            <FormError formError={status} />
          </div>

          <NavigationBlock
            isSubmitting={isSubmitting}
            back={back}
            next={next}
            setSubpage={setSubpage}
            hideSkip={true}
            // SaveButton={isIndividual ? getSaveButton(isSubmitting) : undefined}
            isDisabled={disableNextButton(values, isValid, assignedAddress)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default GeneralFormAddress;
