import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, Link, useHistory } from 'react-router-dom';

import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner/LoadingSpinner';
import Typography from '@Components/Typography';
import {
  AllLCStatuses,
  EnterKeyCode,
  Links,
  LocalStorage,
  PreArchivedDCStatuses,
  ReactResponsiveQueries,
} from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  // ActivityStreamNode,
  DeathCaseStatus,
  SubscriptionPaymentPaymentStatus,
  // useActivityStreamQuery,
  useDeathCasesQuery,
  useLifeCasesQuery,
  UserAccountType,
  useUserSubscriptionPaymentsQuery,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import {
  AccountSettingsSubpages,
  NonAdminSettingsSubpages,
  ProfCompanySettingsSubpages,
  SettingsSubpages,
} from '@Routes/settings/settingsSubpages';
import { logout } from '@Store/auth/auth.actions';
import { getUser } from '@Store/auth/auth.selector';
import { compareDates } from '@Utils/dates';
import { getBooleanValueCookie } from '@Utils/localStorage';
import { saveChangesHandler } from '@Utils/modal';

import styles from './SideMenu.scss';

export interface SideMenuProps {
  page?: MenuItems;
  caseId?: string;
  closeClick?: () => void;
  backToSettingsMenu?: boolean;
  showSideMenuItems?: boolean;
  hasCollaboratorCase?: boolean;
  companyNumberNeeded?: boolean;
}

export enum MenuItems {
  Dashboard,
  Reports,
  Settings,
  ViewDeathCase,
  ViewLifeCase,
  Activity,
}

const SideMenu: React.FunctionComponent<SideMenuProps> = ({
  page,
  caseId,
  closeClick,
  backToSettingsMenu = false,
  showSideMenuItems = false,
  hasCollaboratorCase,
  companyNumberNeeded = false,
}) => {
  const [menuSelected] = React.useState(page || MenuItems.Dashboard);
  const [showSettingsMenu, setShowSettingsMenu] = React.useState(backToSettingsMenu);

  const user = useSelector(getUser);
  const history = useHistory();
  const t = useTranslations();
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const queryString = history.location.search;

  const dcData = useDeathCasesQuery({
    variables: {
      status: PreArchivedDCStatuses,
    },
    fetchPolicy: 'cache-and-network',
  });

  const lcData = useLifeCasesQuery({
    variables: {
      status: AllLCStatuses,
    },
    fetchPolicy: 'cache-and-network',
  });

  const subscriptionsData = useUserSubscriptionPaymentsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const isSubscriptionCanceled = (caseID: any) => {
    const cancelledSubscription = subscriptionsData.data?.userSubscriptionPayments.find(
      (subs: any) =>
        compareDates(subs.subscription.subscriptionRenewal) &&
        subs.paymentStatus === SubscriptionPaymentPaymentStatus.Failed &&
        subs.baseCaseId === caseID
    );
    return cancelledSubscription;
  };

  // const activitySteam = useActivityStreamQuery({
  //   fetchPolicy: 'cache-and-network',
  // });

  // const activitiesNode = activitySteam.data?.activityStream as [ActivityStreamNode];
  // const activities = activitiesNode && activitiesNode.filter((a: ActivityStreamNode) => a.isSeen === false);

  const dispatch = useDispatch();

  const isProfessionalUser =
    user?.accountType === UserAccountType.Professional || user?.accountType === UserAccountType.Agent;

  const dcCount = dcData.data ? dcData.data.deathCases.totalCount : 0;
  const lcCount = lcData.data ? lcData.data.lifeCases.totalCount : 0;

  const casesCount = dcCount + lcCount;

  const caseOnBoardScreen = casesCount === 0 && !isProfessionalUser && !showSideMenuItems;

  const deathCaseView = () => {
    if (caseOnBoardScreen) {
      return false;
    }
    if (hasCollaboratorCase) {
      return false;
    }
    if (!user?.title) {
      return showSideMenuItems;
    }
    if (showSideMenuItems) {
      return true;
    }
    if (companyNumberNeeded) {
      return false;
    }
    return !user?.isFirstLogin;
  };

  const settingsContainerVisibility = () => {
    if (!user?.title) {
      return showSideMenuItems;
    }
    if (caseOnBoardScreen) {
      return false;
    }
    if (hasCollaboratorCase) {
      return false;
    }
    if (showSideMenuItems) {
      return true;
    }
    if (companyNumberNeeded) {
      return false;
    }
    return !user?.isFirstLogin;
  };

  const onCaseClick = (dc: any, isLifeCase?: boolean) => {
    if (isMobile) {
      history.push(generatePath(`${isLifeCase ? Links.lcReady : Links.dcReady}${queryString}`, { id: dc.node.id }));
      return closeClick && closeClick();
    }
    if (getBooleanValueCookie(LocalStorage.settingFormChanged)) {
      return saveChangesHandler(dispatch, `${Links.home}?${queryString}`, dc.node.id);
    }
    return history.push(
      generatePath(`${isLifeCase ? Links.lcReady : Links.dcReady}${queryString}`, { id: dc.node.id })
    );
  };

  const draftCaseStyle = (dc: any) => {
    return dc.node.status === DeathCaseStatus.Draft ? styles.draftContainer : styles.dashboardContainer;
  };

  const noCases = (msg: string) => {
    return (
      <Row constant className={styles.casesContainer} tabIndex={0}>
        <Col className={styles.icon}>
          <Icon className={styles.noDeathCaseIcon} icon="person" />
        </Col>
        <Col className={styles.linkContainer}>
          <Typography className={styles.caseNames} msg={t(`${msg}` as Messages)} size="l" tag="div" color="gray3" />
        </Col>
      </Row>
    );
  };

  const lcIcon = (lcCase: any) => {
    if (isSubscriptionCanceled(lcCase.id)) return 'lock';
    if (lcCase.status === DeathCaseStatus.Draft) return 'alert-triangle';
    return 'person';
  };

  const dcList = () => {
    return (
      <>
        <Typography
          msg={t(Messages.titleDeathCasesActive)}
          size="m"
          tag="div"
          family="helvetica"
          className={styles.caseTitle}
        />
        <div className={classNames(styles.bottomSpacing, styles.casesSection)}>
          {dcCount > 0 &&
            !dcData.loading &&
            dcData.data?.deathCases.edges.map((dc: any, idx: any) => (
              // <Link
              //   key={idx}
              //   onClick={onCaseClick}
              //   to={generatePath(`${Links.dcReady}${queryString}`, { id: dc.node.id })}
              // >
              <div
                onKeyDown={(e: any) => {
                  if (e.keyCode === EnterKeyCode) onCaseClick(dc);
                }}
                //eslint-disable-next-line
                tabIndex={0}
                key={idx}
                onClick={() => onCaseClick(dc)}
                className={styles.clickSection}
              >
                <Row
                  constant
                  className={
                    menuSelected === MenuItems.ViewDeathCase && caseId === dc.node.id
                      ? draftCaseStyle(dc)
                      : styles.casesContainer
                  }
                >
                  <Col className={styles.icon}>
                    <Icon icon={dc.node.status === DeathCaseStatus.Draft ? 'alert-triangle' : 'person'} />
                  </Col>
                  <Col className={styles.linkContainer}>
                    <Typography
                      className={styles.caseNames}
                      msg={`${dc.node.firstName} ${dc.node.lastName}`}
                      size="l"
                      tag="div"
                    />
                  </Col>
                  {dc.node.hasUnseenNotifications && <Col className={styles.newNotify} />}
                </Row>
              </div>
            ))}
          {dcCount === 0 && !dcData.loading && noCases('info_no_death_cases')}
          {dcCount === 0 && dcData.loading && <LoadingSpinner />}
        </div>
      </>
    );
  };

  const lcList = () => {
    return (
      <>
        <Typography
          msg={t(Messages.labelRegisterLives)}
          size="m"
          tag="div"
          family="helvetica"
          className={styles.caseTitle}
        />
        <div className={classNames(styles.bigBottomSpacing, styles.casesSection)}>
          {lcCount > 0 &&
            !lcData.loading &&
            lcData.data?.lifeCases.edges.map((lc: any, idx: any) => (
              // <Link
              //   key={idx}
              //   onClick={onCaseClick}
              //   to={generatePath(`${Links.lcReady}${queryString}`, { id: lc.node.id })}
              // >
              <div
                //eslint-disable-next-line
                tabIndex={0}
                onKeyDown={(e: any) => {
                  if (e.keyCode === EnterKeyCode) onCaseClick(lc, true);
                }}
                key={idx}
                onClick={() => onCaseClick(lc, true)}
                className={styles.clickSection}
              >
                <Row
                  constant
                  className={
                    menuSelected === MenuItems.ViewLifeCase && caseId === lc.node.id
                      ? draftCaseStyle(lc)
                      : styles.casesContainer
                  }
                >
                  <Col className={styles.icon}>
                    <Icon icon={lcIcon(lc.node)} />
                  </Col>
                  <Col className={styles.linkContainer}>
                    <Typography
                      className={styles.caseNames}
                      msg={`${lc.node.firstName} ${lc.node.lastName}`}
                      size="l"
                      tag="div"
                    />
                  </Col>
                </Row>
              </div>
              // </Link>
            ))}
          {lcCount === 0 && !lcData.loading && noCases('info_no_life_cases')}
          {lcCount === 0 && lcData.loading && <LoadingSpinner />}
        </div>
      </>
    );
  };

  const dcLcSideMenuList = () => {
    return (
      <>
        {dcList()}
        {lcList()}
      </>
    );
  };

  const lcDcSideMenuList = () => {
    return (
      <>
        {lcList()}
        {dcList()}
      </>
    );
  };

  const dashboardLink = () => {
    if (getBooleanValueCookie(LocalStorage.settingFormChanged)) {
      saveChangesHandler(dispatch, Links.home);
    } else {
      history.push(generatePath(Links.home));
    }
  };

  return (
    <div className={styles.container}>
      {!showSettingsMenu ? (
        <>
          <div className={styles.lifeLedgerIcon}>
            <div
              //eslint-disable-next-line
              tabIndex={0}
              onKeyDown={(e: any) => {
                if (e.keyCode === EnterKeyCode) {
                  dashboardLink();
                }
              }}
              className={styles.clickSection}
              onClick={() => dashboardLink()}
            >
              <img
                width={isTablet ? '128' : '173'}
                height="37"
                src="https://lifeledger.com/wp-content/uploads/2021/08/Black.svg"
                alt="" // eslint-disable-next-line
              />
            </div>
            {isMobile && <Icon onClick={closeClick} icon="close" className={styles.closeIcon} size={IconSizes.sxs} />}
          </div>

          {deathCaseView() && (
            <div id="sideMainMenu">
              <Row
                constant
                className={classNames(
                  menuSelected === MenuItems.Dashboard ? styles.dashboardContainer : styles.settingsContainer,
                  styles.mb32
                )}
              >
                <Col className={styles.icon}>
                  <Icon icon="dashboard" />
                </Col>
                <Col className={styles.linkContainer}>
                  {/* <Link to={generatePath(`${Links.home}${queryString}`)}> */}
                  <Typography
                    onClick={() => dashboardLink()}
                    tabIndex={0}
                    msg={t(Messages.pageDashboard)}
                    size="l"
                    tag="div"
                    color="newGray"
                    onKeyDown={(e: any) => {
                      if (e.keyCode === EnterKeyCode) {
                        dashboardLink();
                      }
                    }}
                  />
                  {/* </Link> */}
                </Col>
              </Row>

              {/* <Row
                constant
                className={classNames(
                  menuSelected === MenuItems.Activity ? styles.dashboardContainer : styles.settingsContainer,
                  styles.mb32
                )}
              >
                <Col className={styles.icon}>
                  <Icon icon="bell" />
                </Col>
                <Col className={styles.linkContainer}>
                  <Link to={`${Links.activity}${queryString}`}>
                    <Typography msg={t(Messages.pageActivity)} size="l" tag="div" color="newGray" />
                  </Link>
                </Col>
                {activities && activities.length > 0 && <Col className={styles.newNotify} />}
              </Row> */}
              {(dcCount > 0 && !dcData.loading) ||
              (dcCount === 0 && !dcData.loading && lcCount === 0 && !lcData.loading)
                ? dcLcSideMenuList()
                : lcDcSideMenuList()}
            </div>
          )}

          {settingsContainerVisibility() && (
            <>
              <Row
                id="settingsLink"
                column
                className={classNames(
                  menuSelected === MenuItems.Settings ? styles.dashboardContainer : styles.settingsContainer,
                  styles.divSpacing
                )}
              >
                <Row
                  tabIndex={0}
                  onClick={() =>
                    history.push(
                      generatePath(`${Links.settings}?${queryString}`, {
                        subpage:
                          user?.accountType === UserAccountType.Professional && !user.isPortalAdmin
                            ? NonAdminSettingsSubpages.IDValidation
                            : SettingsSubpages.Account,
                      })
                    )
                  }
                  onKeyDown={(e: any) => {
                    if (e.keyCode === EnterKeyCode) {
                      history.push(
                        generatePath(`${Links.settings}?${queryString}`, {
                          subpage:
                            user?.accountType === UserAccountType.Professional && !user.isPortalAdmin
                              ? NonAdminSettingsSubpages.IDValidation
                              : SettingsSubpages.Account,
                        })
                      );
                    }
                  }}
                  className={styles.clickSection}
                  constant
                >
                  <Col className={styles.icon}>
                    <Icon icon="settings" />
                  </Col>
                  <Col>
                    {isMobile ? (
                      <span onClick={() => setShowSettingsMenu(true)}>
                        <Typography
                          className={styles.nameSpacing}
                          msg={`${user?.firstName} ${user?.lastName}`}
                          size="l"
                          tag="div"
                          color="newGray"
                        />
                      </span>
                    ) : (
                      // <Link
                      //   to={generatePath(`${Links.settings}?${queryString}`, {
                      //     subpage:
                      //       user?.accountType === UserAccountType.Professional && !user.isPortalAdmin
                      //         ? NonAdminSettingsSubpages.IDValidation
                      //         : SettingsSubpages.Account,
                      //   })}
                      // >
                      <Typography
                        className={styles.nameSpacing}
                        msg={`${user?.firstName} ${user?.lastName}`}
                        size="l"
                        tag="div"
                        color="newGray"
                      />
                      // </Link>
                    )}
                  </Col>
                  <Col className={styles.settingsIconRight}>
                    <Icon size={IconSizes.s} icon="chevronRight" />
                  </Col>
                </Row>
                {/* {user?.verificationStatus !== UserVerificationStatus.Verified && dcCount > 0 && (
                  <Row>
                    <Col size={1} />

                    <Icon className={styles.missingItemsIcon} icon="lock" size={IconSizes.sss} />
                    <Typography
                      className={styles.sent}
                      msg={
                        enableTuCheck
                          ? t('missing_item_prefix_user_verification' as Messages)
                          : t('missing_item_prefix_user_document' as Messages)
                      }
                      tag="span"
                      bold
                      size="s"
                    />
                  </Row>
                )} */}
              </Row>

              <Row
                constant
                tabIndex={0}
                className={classNames(styles.settingsContainer, styles.clickSection, styles.logoutSpacing)}
                onClick={() => {
                  if (getBooleanValueCookie(LocalStorage.settingFormChanged)) {
                    saveChangesHandler(dispatch, `${Links.home}?${queryString}`, '', '', true);
                  } else {
                    dispatch(logout());
                  }
                }}
                onKeyDown={(e: any) => {
                  if (e.keyCode === EnterKeyCode) {
                    if (getBooleanValueCookie(LocalStorage.settingFormChanged)) {
                      saveChangesHandler(dispatch, `${Links.home}?${queryString}`, '', '', true);
                    } else {
                      dispatch(logout());
                    }
                  }
                }}
              >
                <Col className={styles.icon}>
                  <Icon icon="logout" />
                </Col>
                <Col className={styles.linkContainer}>
                  <Typography msg="Log out" size="l" tag="div" color="newGray" />
                </Col>
              </Row>
            </>
          )}
        </>
      ) : (
        <>
          <div className={styles.lifeLedgerIcon}>
            <div
              className={classNames(styles.clickSection, styles.backToMenuContainer)}
              onClick={() => setShowSettingsMenu(false)}
            >
              <Icon size={IconSizes.ssm} icon="chevronLeft" className={styles.iconPosition} />
              <Typography className={styles.backToText} msg={t(Messages.linkBackToMenu)} size="l" tag="div" />
            </div>
            {isMobile && (
              <Icon onClick={closeClick} icon="close" className={styles.settingsCloseIcon} size={IconSizes.sxs} />
            )}
          </div>
          <div className={styles.leftSpacing}>
            <Row constant className={styles.title} alignCenter wrap>
              <span className={styles.iconSpacing}>
                <Icon icon="settings" size={IconSizes.sss} className={styles.settingsIcon} />
              </span>
              <Typography msg={t(Messages.titleSettings)} tag="span" />
            </Row>
            <Typography
              msg={t('msg_title_settings_subitem_account_management' as Messages)}
              tag="div"
              size="m"
              className={styles.accountMgmt}
            />

            {Object.values(AccountSettingsSubpages).map((settingHeader: any, idx: number) => {
              return (
                <Link
                  key={idx}
                  onClick={closeClick}
                  to={generatePath(`${Links.settings}?${queryString}`, { subpage: settingHeader })}
                  className={styles.settingsLink}
                >
                  <div className={styles.textContainer}>
                    <Typography
                      msg={t(`${Messages.titleSettingsSubItemPrefix}${settingHeader.toLowerCase()}` as Messages)}
                      tag="div"
                      size="lbx"
                    />
                  </div>
                  <div className={styles.chevronRightContainer}>
                    <Icon size={IconSizes.ssm} icon="chevronRight" />
                  </div>
                </Link>
              );
            })}

            {user?.accountType === UserAccountType.Professional && (
              <>
                <Typography
                  msg="Company management"
                  tag="div"
                  size="m"
                  className={classNames(styles.accountMgmt, styles.bigTopSpacing)}
                />
                {Object.values(ProfCompanySettingsSubpages).map((settingHeader: any, idx: number) => {
                  return (
                    <Link
                      key={idx}
                      onClick={closeClick}
                      to={generatePath(Links.settings, { subpage: settingHeader })}
                      className={styles.settingsLink}
                    >
                      <div className={styles.textContainer}>
                        <Typography
                          msg={t(`${Messages.titleSettingsSubItemPrefix}${settingHeader.toLowerCase()}` as Messages)}
                          tag="div"
                          size="lbx"
                        />
                      </div>
                      <div className={styles.chevronRightContainer}>
                        <Icon size={IconSizes.ssm} icon="chevronRight" />
                      </div>
                    </Link>
                  );
                })}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SideMenu;
