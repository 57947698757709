import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';

import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { EnterKeyCode, Links, LocalStorage } from '@Config/constants';
import { Messages } from '@Config/messages';
import { UserAccountType, UserVerificationStatus } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import {
  SettingsSubpages,
  AccountSettingsSubpages,
  // CompanySettingsSubpages,
  ProfAccountSettingsSubpages,
  ProfCompanySettingsSubpages,
  ServiceProviderSettingsSubpages,
  ServiceProviderCompanySettingsSubpages,
} from '@Routes/settings/settingsSubpages';
import { getEnableTuCheck } from '@Store/app/app.selector';
import { getUser } from '@Store/auth/auth.selector';
import { getBooleanValueCookie } from '@Utils/localStorage';
import { saveChangesHandler } from '@Utils/modal';

import styles from './SettingsMenuComponent.scss';

export interface SettingsMenuComponentProps {
  active: string;
}

export interface SettingsMenuComponentDispatchProps {}

interface SettingsMenuComponentPrivateProps extends SettingsMenuComponentProps, SettingsMenuComponentDispatchProps {}

type UnfinishedConfig = { [key in SettingsSubpages]: boolean };

const SettingsMenuComponent: React.FunctionComponent<SettingsMenuComponentPrivateProps> = ({ active }) => {
  const t = useTranslations();
  const history = useHistory();
  const queryString = history.location.search;
  const dispatch = useDispatch();
  const enableTuCheck = useSelector(getEnableTuCheck);

  const user = useSelector(getUser);
  const isBirthDateRequired = () => {
    return user?.accountType === UserAccountType.Individual
      ? !user?.birthDate || !user?.address1 || !user?.city
      : false;
  };
  const unfinishedMatrix: UnfinishedConfig = {
    [SettingsSubpages.Account]: !user?.accountType || isBirthDateRequired(),
    // [SettingsSubpages.IDValidation]: user?.verificationStatus !== UserVerificationStatus.Verified,
    [SettingsSubpages.PasswordChange]: false,
    [SettingsSubpages.Billing]: false,
  };

  const idVerificationPage = (pages: any) => {
    if (user?.verificationStatus === UserVerificationStatus.Verified) {
      return pages;
    }
    return pages.filter((x: any) => x !== AccountSettingsSubpages.IDValidation);
  };

  const getSettingsOptions = () => {
    if (user?.accountType === UserAccountType.Professional || user?.accountType === UserAccountType.Agent) {
      if (user.isPortalAdmin) {
        return {
          account_management: Object.values(ProfAccountSettingsSubpages),
          company_management: Object.values(ProfCompanySettingsSubpages),
        };
      }
      return {
        account_management: Object.values(ProfAccountSettingsSubpages),
      };
    }
    if (user?.accountType === UserAccountType.ServiceProvider) {
      if (user.isPortalAdmin) {
        return {
          account_management: Object.values(ServiceProviderSettingsSubpages),
          company_management: Object.values(ServiceProviderCompanySettingsSubpages),
        };
      }
      return {
        account_management: Object.values(ServiceProviderSettingsSubpages),
      };
    }
    return {
      account_management: idVerificationPage(Object.values(AccountSettingsSubpages)),
    };
  };
  const settingsLinks = getSettingsOptions();

  const settingsMenuClick = (sidebarItem: any) => {
    if (getBooleanValueCookie(LocalStorage.settingFormChanged)) {
      saveChangesHandler(dispatch, `${Links.settings}?${queryString}`, sidebarItem);
    } else {
      history.push(generatePath(`${Links.settings}?${queryString}`, { subpage: sidebarItem }));
    }
  };

  const getMenuLabels = (sidebarItem: any) => {
    if (sidebarItem === 'validation') {
      return enableTuCheck
        ? t(`${Messages.titleSettingsSubItemPrefix}${sidebarItem}` as Messages)
        : t(`${Messages.titleSettingsSubItemPrefix}document` as Messages);
    }
    return t(`${Messages.titleSettingsSubItemPrefix}${sidebarItem}` as Messages);
  };

  const getSettingsLinks = (settingHeader: any) => {
    return Object.values(settingsLinks[settingHeader]).map((sidebarItem: any, idx: number) => {
      const isActive = active === sidebarItem;

      return (
        <Row
          className={classNames(styles.navigationItem, { [styles.active]: isActive })}
          key={idx}
          size={12}
          alignCenter
          constant
        >
          <div
            className={classNames(styles.navigationLinkContainer, {
              [styles.active]: isActive,
              [styles.isvalidationUnfinishedSpacing]: sidebarItem === ProfAccountSettingsSubpages.IDValidation,
            })}
          >
            <Typography
              msg={getMenuLabels(sidebarItem)}
              tag="span"
              bold={isActive}
              className={styles.settingsMenuItem}
              onClick={() => settingsMenuClick(sidebarItem)}
              onKeyDown={(e: any) => {
                if (e.keyCode === EnterKeyCode) {
                  settingsMenuClick(sidebarItem);
                }
              }}
              //eslint-disable-next-line
              tabIndex={0}
            />
            {unfinishedMatrix[sidebarItem] && (
              <span
                onClick={() => history.push(generatePath(`${Links.settings}?${queryString}`, { subpage: sidebarItem }))}
              >
                <Typography msg={t(Messages.labelUnfinished)} tag="span" color="orange" size="s" />
              </span>
            )}
          </div>
        </Row>
      );
    });
  };
  return (
    <div className={styles.settingsMenuContainer}>
      <Row className={styles.title} alignCenter wrap>
        <span className={styles.iconSpacing}>
          <Icon icon="settings" size={IconSizes.sss} className={styles.settingsIcon} />
        </span>
        <Typography msg={t(Messages.titleSettings)} tag="span" />
      </Row>
      <div className={styles.linksContainer}>
        {Object.keys(settingsLinks).map((settingHeader) => {
          return (
            <div key={settingHeader} className={styles.settingsSection}>
              <Typography
                msg={t(`${Messages.titleSettingsSubItemPrefix}${settingHeader}` as Messages)}
                tag="div"
                size="xs"
                bold
                className={styles.settingsHeader}
              />
              {getSettingsLinks(settingHeader)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SettingsMenuComponent;
