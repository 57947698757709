import ReactGA from 'react-ga4';
// Initialize Google Analytics with your Measurement ID
export const initializeGA = () => {
  ReactGA.initialize('G-8TK2E4B8VB'); // Replace with your actual GA4 Measurement ID
};

const trackEvent = (category: string, action: string, label: string, value?: number) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
};

export const handleCantNotify = (serviceProviderName: string) => {
  trackEvent(
    `Service Provider Notifications`, // Unified Event Category
    `Can't Notify ${serviceProviderName}`,
    `Service Provider: ${serviceProviderName}`, // Service provider name in Event Label
    1 // Event Value
  );
};
