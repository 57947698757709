import classNames from 'classnames';
import { FieldProps, useField } from 'formik';
import * as React from 'react';
import { useState } from 'react';

import InputContainer from '@Components/form/inputs/InputContainer';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import {
  ServiceProviderIdentifierType,
  useOrganisationsLazyQuery,
  useServiceProviderSearchLazyQuery,
} from '@Graphql/graphqlTypes.generated';
import { hasFieldError } from '@Utils/form';

import styles from '../TextInput/TextInput.scss';

import autoCompletestyles from './AutoComplete.scss';

export interface AutoCompleteProps {
  placeholder: string;
  label: string;
  required?: boolean;
  showIcon?: boolean;
  disabled?: boolean;
  showLabel?: boolean;
  className?: string;
  withInputIcon?: boolean;
  fullWidth?: boolean;
  onSelectClick: Function;
  isCompanyCard?: boolean;
  organisationControl?: boolean;
}

interface AutoCompletePrivateProps extends AutoCompleteProps, FieldProps {}

const AutoComplete: React.FunctionComponent<AutoCompletePrivateProps> = (props) => {
  const {
    label,
    required,
    placeholder,
    form,
    withInputIcon,
    onSelectClick,
    fullWidth,
    isCompanyCard,
    showIcon,
    organisationControl,
    ...restProps
  } = props;
  const [field, meta, helpers] = useField(props.field);
  const { setValue } = helpers;
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState<any[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState('');
  const hasError = hasFieldError(meta, form);
  const dropdownRef = React.useRef<HTMLInputElement | null>(null); // Specify type for input ref

  const [getProvidersData, providersValues] = useServiceProviderSearchLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
    onCompleted: () => {
      const suggestions = providersValues.data?.serviceProviderSearch;
      if (suggestions && suggestions.length > 0) {
        if (isCompanyCard) {
          const companyNameChange = {
            id: '1000',
            identifierType: ServiceProviderIdentifierType.Bank,
            logo: 'briefcase',
            name: 'The company is not in this list',
          };
          suggestions.unshift(companyNameChange);
        }
        setActiveSuggestion(0);
        setFilteredSuggestions(suggestions || []);
        setShowSuggestions(true);
      }
    },
  });

  const [getOrganisations, organisationalValues] = useOrganisationsLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
    onCompleted: () => {
      const suggestions = organisationalValues.data?.organisations;
      if (suggestions && suggestions.length > 0) {
        setActiveSuggestion(0);
        setFilteredSuggestions(suggestions || []);
        setShowSuggestions(true);
      } else {
        setActiveSuggestion(0);
        setFilteredSuggestions([]);
        setShowSuggestions(false);
      }
    },
  });

  const onChange = (e: any) => {
    const userInputVal = e.currentTarget.value;
    const triggerPoint = organisationControl ? 0 : 1;
    if (userInputVal.length > triggerPoint) {
      if (organisationControl) {
        getOrganisations({
          variables: { search: userInputVal },
        });
      } else {
        getProvidersData({
          variables: { searchString: userInputVal },
        });
      }
    } else {
      setFilteredSuggestions([]);
      setShowSuggestions(false);
    }
    setUserInput(userInputVal);
    setValue(userInputVal);
  };

  const handleClickOutside = (event: MouseEvent | KeyboardEvent) => {
    if (event instanceof MouseEvent && dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setShowSuggestions(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleClickOutside);
    };
  });

  const onClick = (e: any, suggestion: any) => {
    setActiveSuggestion(0);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setUserInput(e.target.innerText);
    setValue(e.target.innerText);
    onSelectClick(organisationControl ? suggestion.organisationType : suggestion.id);
  };

  // const onKeyDown = (e: any) => {
  //   if (e.keyCode === 13) {
  //       setActiveSuggestion(0);
  //       setShowSuggestions(false);
  //       setUserInput(filteredSuggestions[activeSuggestion]);
  //   } else if (e.keyCode === 38) {
  //     if (activeSuggestion === 0) {
  //       return;
  //     }
  //     setActiveSuggestion(activeSuggestion - 1);
  //   }
  //   // User pressed the down arrow, increment the index
  //   else if (e.keyCode === 40) {
  //     if (activeSuggestion - 1 === filteredSuggestions.length) {
  //       return;
  //     }
  //     setActiveSuggestion(activeSuggestion + 1);
  //   }
  // };

  const renderLogo = (suggestion: any) => {
    if (suggestion.id === '1000') {
      return (
        <Col className={autoCompletestyles.briefCaseContainer}>
          <Icon icon="briefcase" />
        </Col>
      );
    }
    if (suggestion.logo) {
      return (
        <Col>
          <img className={autoCompletestyles.logoWrapper} src={suggestion.logo} alt="" />
        </Col>
      );
    }
    return (
      <Col>
        <div className={autoCompletestyles.initialLogoContainer}>
          <span>{suggestion.name.charAt(0).toUpperCase()}</span>
        </div>
      </Col>
    );
  };

  let suggestionsListComponent;

  if (showSuggestions && userInput) {
    if (filteredSuggestions.length) {
      suggestionsListComponent = (
        <ul
          className={classNames(autoCompletestyles.suggestions, {
            [autoCompletestyles.suggestionForCompany]: isCompanyCard,
          })}
        >
          {filteredSuggestions.map((suggestion: any, index: number) => {
            let className;
            // Flag the active suggestion with a class
            if (index === activeSuggestion) {
              className = autoCompletestyles.suggestionActive;
            }
            return (
              <li
                className={classNames(className, autoCompletestyles.listHover)}
                key={suggestion.id}
                onClick={(e: any) => onClick(e, suggestion)}
              >
                {organisationControl ? (
                  <Row>{suggestion.name}</Row>
                ) : (
                  <Row constant className={autoCompletestyles.logoGap} alignCenter>
                    {renderLogo(suggestion)}
                    <Col>{suggestion.name}</Col>
                  </Row>
                )}
              </li>
            );
          })}
        </ul>
      );
    } else {
      suggestionsListComponent = <div />;
    }
  }

  return (
    <InputContainer
      label={label}
      meta={meta}
      form={form}
      required={required}
      fullWidth={fullWidth}
      containerClass={styles.wrapper}
      showErrorIcon={true}
    >
      <div ref={dropdownRef}>
        <input
          {...field}
          {...restProps}
          placeholder={placeholder}
          aria-label={placeholder}
          type="text"
          autoComplete="off"
          onChange={onChange}
          //    onKeyDown={onKeyDown}
          value={userInput}
          className={classNames(styles.input, { [styles.error]: hasError, [styles.withInputIcon]: withInputIcon })}
        />
        {suggestionsListComponent}
        {showIcon && <Icon icon="search" className={autoCompletestyles.searchIcon} size={IconSizes.s} />}
      </div>
    </InputContainer>
  );
};

export default AutoComplete;
