import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory, useLocation } from 'react-router';

import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Links, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { UserAccountType } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './RegisterForm.scss';

const RegisterForm: React.FunctionComponent = () => {
  const t = useTranslations();
  const history = useHistory();
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const paramCollaboratorKey = searchParams.get('collaborator_key') || undefined;
  const refSrcKey = searchParams.get('ref') || undefined;
  const individualLink = refSrcKey ? `${Links.individualRegister}?ref=${refSrcKey}` : Links.individualRegister;

  const chevronRightIcon = (color?: string) => {
    return <FontAwesomeIcon icon={faChevronRight} size="xs" color={color} />;
  };

  return (
    <Row className={styles.container}>
      <Col alignCenter size={3}>
        <Typography msg={t(Messages.labelRegisterType)} tag="h3" bold />
      </Col>
      <Col className={styles.verticalLine} size={1} />
      <Col size={1} />
      <Col size={isTablet ? 7 : 6}>
        <Row column>
          <Col
            tabindex={0}
            className={styles.bereavementSection}
            onClick={() =>
              history.push(
                generatePath(individualLink, {
                  accountType: UserAccountType.Individual.toLowerCase(),
                  collaboratorKey: paramCollaboratorKey,
                })
              )
            }
            onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                history.push(
                  generatePath(individualLink, {
                    accountType: UserAccountType.Individual.toLowerCase(),
                    collaboratorKey: paramCollaboratorKey,
                  })
                );
              }
            }}
          >
            <Row constant>
              <Col size={8}>
                <Typography msg={t(Messages.labelRegisterBereavement)} tag="span" size="l" bold />
              </Col>
              <Col textCenter={!isMobile} textRight={isMobile} alignCenter size={4}>
                {chevronRightIcon('black')}
              </Col>
            </Row>
          </Col>

          <Col
            tabindex={0}
            className={styles.professionalSection}
            onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                history.push(
                  generatePath(individualLink, {
                    accountType: UserAccountType.Professional.toLowerCase(),
                    collaboratorKey: paramCollaboratorKey,
                  })
                );
              }
            }}
            onClick={() =>
              history.push(
                generatePath(individualLink, {
                  accountType: UserAccountType.Professional.toLowerCase(),
                  collaboratorKey: paramCollaboratorKey,
                })
              )
            }
          >
            <Row constant>
              <Col size={8}>
                <Typography msg={t(Messages.labelRegisterProfessional)} tag="span" size="l" bold />
              </Col>
              <Col textCenter={!isMobile} textRight={isMobile} alignCenter size={4}>
                {chevronRightIcon('#F6C600')}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default RegisterForm;
