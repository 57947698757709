// import * as MSAL from '@azure/msal-browser'
// import { MsalProvider } from '@azure/msal-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { gapi } from 'gapi-script';
import * as React from 'react';
// import { AzureAD } from 'react-aad-msal';
import TagManager from 'react-gtm-module';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath, Redirect, Route, Switch } from 'react-router-dom';

import DocumentTitle from '@Components/DocumentTitle';
import { googleClientId, Links } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useBusinessPartnersQuery, useGetMeQuery, UserAccountType } from '@Graphql/graphqlTypes.generated';
import { privateRoutes, RouteInfo } from '@Routes/appRoutes';
import PersonalisedLoginPage from '@Routes/auth/PersonalisedLoginPage/PersonalisedLoginPage';
import { getGoogleTagManagerId, getStripeApiPublicKey, isAppReady } from '@Store/app/app.selector';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
// import { authenticationParameters, msalConfig, msalInstance } from '@Utils/helpers';
import { authenticatedOnlyComponent } from '@Utils/router';

import routes from '../routes';
import { initializeGA } from '../utils/analytics';

import ErrorWrapper from './ErrorWrapper';
import Loading from './LoadingSpinner';

const renderRouteComponent = (props: JSX.IntrinsicAttributes, route: RouteInfo) => {
  return (
    <DocumentTitle title={route.title}>
      <route.component {...props} />
    </DocumentTitle>
  );
};

const App: React.FunctionComponent<{}> = () => {
  const appReady: boolean = useSelector(isAppReady);
  const locale = useSelector(getCurrentLanguage);
  const publishableKey = useSelector(getStripeApiPublicKey);
  const googleTagManagerId = useSelector(getGoogleTagManagerId);
  const user = useGetMeQuery({
    fetchPolicy: 'network-only',
  });

  const bpData = useBusinessPartnersQuery({});

  // const msalInstance = new MSAL.PublicClientApplication(msalConfig);

  React.useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: googleClientId,
        scope: '',
      });
    }
    gapi.load('client:auth2', start);
    initializeGA();
  });

  if (!appReady || bpData.loading) {
    return <Loading fullscreen />;
  }

  const onErrorRedirectUrl = () => {
    if (user.data?.me.info.accountType === UserAccountType.ServiceProvider) {
      return generatePath(Links.providerDashboard);
    }
    return generatePath(Links.home);
  };

  const getRoutes = () => {
    const bpRoutInfo = bpData.data?.businessPartners.map((busiPart: any) => {
      return {
        path: `/partner/${busiPart.shortUrl}`,
        component: PersonalisedLoginPage,
        title: 'page_title_be_spoke_login' as Messages,
        isServiceProviderRoute: false,
        isBusinessPartnerRoute: true,
      } as RouteInfo;
    });

    const bpNoRoutInfo = bpData.data?.businessPartners.map((busiPart: any) => {
      return {
        path: `/${busiPart.shortUrl}`,
        component: PersonalisedLoginPage,
        title: 'page_title_be_spoke_login' as Messages,
        isServiceProviderRoute: false,
        isBusinessPartnerRoute: true,
        target: `/partner/${busiPart.shortUrl}`,
      } as RouteInfo;
    });

    const privateRouting = privateRoutes.map((item: RouteInfo) => {
      return {
        ...item,
        component: authenticatedOnlyComponent(item.component),
      };
    });

    const allRoutes = routes.concat(bpRoutInfo || [], bpNoRoutInfo || [], privateRouting);

    if (user.data?.me.info.accountType === UserAccountType.ServiceProvider) {
      const highLevel = allRoutes.filter((r: RouteInfo) => r.isServiceProviderRoute || r.path === Links.login);
      highLevel.push(allRoutes.filter((r: RouteInfo) => r.path === Links.providerDCInfo)[0]);
      highLevel.push(allRoutes.filter((r: RouteInfo) => r.path === Links.forgotPassword)[0]);
      highLevel.push(allRoutes.filter((r: RouteInfo) => r.path === Links.register)[0]);
      return highLevel.filter((r: RouteInfo) => r.path !== Links.professionalDashboard);
    }
    if (user.data?.me.info.accountType === UserAccountType.Professional) {
      const highLevel = allRoutes.filter((r: RouteInfo) => !r.isServiceProviderRoute || r.path === Links.settings);
      return highLevel;
    }

    return allRoutes;
  };

  const controlledRoutes = getRoutes();

  const stripePromise = loadStripe(publishableKey);

  const tagManagerArgs = {
    gtmId: googleTagManagerId,
  };

  if (window.location.hostname !== 'localhost') {
    TagManager.initialize(tagManagerArgs);
  }

  return (
    <Elements stripe={stripePromise} options={{ locale }}>
      {/* <MsalProvider instance={msalInstance}> */}
      {/* <AzureAD provider={msalInstance}> */}
      <Switch>
        {controlledRoutes.map((route) => (
          <Route
            path={route.path}
            exact={true}
            key={route.path}
            component={(props: JSX.IntrinsicAttributes) => renderRouteComponent(props, route)}
          >
            {route.target && <Redirect from={route.path} to={route.target} />}
          </Route>
        ))}

        <Redirect to={onErrorRedirectUrl()} />
      </Switch>
      {/* </MsalProvider> */}
      {/* </AzureAD> */}
    </Elements>
  );
};

export default hot(module)(ErrorWrapper(App));
